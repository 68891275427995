import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

interface IStyleProps {
  isMenuVisible: boolean
}

export default makeStyles( ( theme: ITheme ) => ( {

  sideMenuRoot: {
    width: '100%',
    position: 'relative',
    maxHeight: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    [theme.breakpoints.up( 'md' )]: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  },

  leftPaneRoot: {
    display: ( { isMenuVisible }: IStyleProps ) => isMenuVisible ? 'initial' : 'none',
    position: 'absolute',
    zIndex: 100,
    flexGrow: 0,
    width: '100%',
    boxShadow: theme.shadows[3],

    [theme.breakpoints.up( 'md' )]: {
      display: 'initial',
      backgroundColor: theme.palette.background.paper,
      position: 'initial',
      height: '100%',
      flexGrow: 1,
      margin: theme.spacing( 0 )
    }
  },


  leftPaneContent: {
    width: '100%',
    maxWidth: 360,
    height: '100%',
    border: `1px solid ${ theme.palette.text.mildText }`,
    backgroundColor: theme.palette.background.paperLight,
    padding: theme.spacing( 5, 0, 3, 1 ),
    borderRadius: theme.roundness,
    [theme.breakpoints.up( 'md' )]: {
      padding: theme.spacing( 1, 0, 3, 1 ),
      backgroundColor: theme.palette.background.paper
    }
  },

  content: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing( 0, 1 ),
    height: '100%',
    border: `1px solid ${ theme.palette.text.mildText }`,
    margin: theme.spacing( 0 ),
    borderRadius: theme.roundness,
    [theme.breakpoints.up( 'md' )]: {
      margin: theme.spacing( 0, 1 ),
      padding: theme.spacing( 0, 3 ),
      overflow: 'scroll'
    }
  },

  sectionTitle: {
    color: theme.palette.text.mildText,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase'
  },

  listItem: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize
  },

  activeListItem: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },

  sectionDivider: {
    marginTop: theme.spacing( 1 ),
    width: '90%'
  },

  activeListItemContainer: {
    backgroundColor: theme.palette.secondary.main,
    borderBottomLeftRadius: theme.spacing( 1 ),
    borderTopLeftRadius: theme.spacing( 1 )
  },

  menuBtn: {
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 200,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing( 2 ),
    '& button': {
      margin: theme.spacing( 1 ),
      backgroundColor: theme.palette.background.paperLight,
      borderRadius: '50%',
      border: `1px ${ theme.palette.text.mildText } solid`
    },
    [theme.breakpoints.up( 'md' )]: {
      // height: 30,
      // width: 30,
      display: 'block',
      position: 'initial',
      left: 0,
      top: 0,
      '& button': {
        margin: theme.spacing( 1, 0 )
      }
    }
  }


} ) )