import React, { FC } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { StateUpdater } from '../VistaHandler'

import useStyles from './styles'
import { AiOutlineHome, AiOutlineLock, AiOutlineUnlock } from 'react-icons/all'
import theme from '../../../App.theme'
import AuthStorage from '../../../lib/AuthStorage'
import IoT from '../../../lib/IoT'

interface IVistaUsuario {
  stateUpdater: StateUpdater
}

const VistaUsuario: FC<IVistaUsuario> = ( { stateUpdater } ) => {
  const styles = useStyles()
  const user = AuthStorage.user.get()

  const secureHub = async ( isArmado: boolean ) => await IoT.publish( `smarthub/secure`, {
    id_hub: user!.idHub,
    secureHub: isArmado
  } )


  return (
      <Grid container className={ styles.usuariosRoot }>
        <Grid container className={ styles.titleContainer }>
          <Grid item xs={ 11 }>
            <Typography variant={ 'h1' }>Activa o desactiva la seguridad en tu Smarthub.</Typography>
          </Grid>

          <Grid item xs={ 1 }>
            <IconButton
                size={ 'medium' }
                aria-label={ 'ACTIVAR' }
                onClick={ () => stateUpdater.setEtapa( 'lobby' ) }>
              <AiOutlineHome/>
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={ 4 } className={ styles.lockContainer }>
          <Grid item>
            <IconButton
                size={ 'small' }
                aria-label={ 'ACTIVAR' }
                onClick={ async () => await secureHub( true ) }
                style={ { backgroundColor: theme.palette.status.success } }>
              <AiOutlineLock/>
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
                size={ 'small' }
                aria-label={ 'DESACTIVAR' }
                onClick={ async () => await secureHub( false ) }
                style={ { backgroundColor: theme.palette.status.danger } }>
              <AiOutlineUnlock/>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
  )

}

export default VistaUsuario
