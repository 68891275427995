// noinspection JSUnfilteredForInLoop

import * as React from "react"
import { FC, useReducer } from "react"
import { Object } from "./contextHelpers"
import { DispatchActionType, ReducerAction } from "./contextHelpers"

type DispatchActions<T, K, P> = {
  [U in keyof K]: (dispatch: DispatchActionType<P, T>, state?: T) => K[U];
};

const createDataContext = <T, K, P>(
  reducer: (state: T, action: ReducerAction<P, T>) => T,
  actions: DispatchActions<T, K, P>,
  defaultValue: T
) => {
  const Context = React.createContext<any>(null)
  const Provider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue)
    const boundActions: Object = {}

    for (const key in actions) {
      boundActions[key] = actions[key](dispatch, state)
    }

    return (
      <Context.Provider value={ { state, ...boundActions } }>
        { children }
      </Context.Provider>
    )
  }

  return { Context, Provider }
}

export default createDataContext
