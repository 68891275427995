import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

interface IStyleProps {
  disabled?: boolean
  small?: boolean
  fullWidth?: boolean
  light?: boolean
  discrete?: boolean
  isLoading?: boolean
  color?: string
}

export default makeStyles( ( theme: ITheme ) => ( {
  button: {
    boxShadow: ( props: IStyleProps ) => props.discrete ? 'none' : theme.shadows[3],
    height: ( props: IStyleProps ) => props.small ? theme.spacing( 4 ) : 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ( props: IStyleProps ) => ( props.fullWidth ? '100%' : 'auto' ),

    padding: ( props: IStyleProps ) =>
        props.discrete
            ? theme.spacing( 0 )
            : props.small
                ? theme.spacing( 0.5, 0.75 )
                : theme.spacing( 1, 3.5 ),

    background: ( props: IStyleProps ) =>
        props.disabled
            ? theme.palette.text.lightMild
            : !props.discrete
                ? props.color
                    ? props.color
                    : theme.palette.primary.main
                : theme.palette.extras.none,

    border: ( { light, discrete, disabled }: IStyleProps ) =>
        disabled
            ? `1px solid ${ theme.palette.text.disabled }`
            : !discrete
                ? `1px solid ${
                    light ? theme.palette.text.lightText : theme.palette.text.primary
                }`
                : '',
    borderRadius: theme.roundness,

    fontSize: theme.typography.body2.fontSize,
    textTransform: ( { small }: IStyleProps ) => ( small ? 'none' : 'uppercase' ),
    textDecoration: 'none',
    color: ( { light, discrete }: IStyleProps ) =>
        light ? theme.palette.text.lightText : !discrete ? theme.palette.text.lightText : theme.palette.text.primary,
    lineHeight: theme.lineHeight( 1.75 ),
    letterSpacing: ( { small }: IStyleProps ) =>
        small ? theme.letterspacing( 0.3 ) : theme.letterspacing( 0.75 ),
    transition: theme.palette.extras.transitions.default,

    '&:hover': {
      textDecoration: 'none',
      background: ( { discrete }: IStyleProps ) =>
          !discrete ? theme.palette.primary.main : theme.palette.background.paper,
      color: ( { discrete }: IStyleProps ) =>
          !discrete
              ? theme.palette.text.lightText
              : theme.palette.primary.main,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main
      },
      '&$disabled': {
        backgroundColor: theme.palette.text.disabled
      }
    },

    [theme.breakpoints.up( 'md' )]: {
      fontSize: theme.typography.body2.fontSize
    }
  },

  loaderRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main
  }
} ) )
