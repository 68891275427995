import { Box, Divider, Grid, Typography } from '@material-ui/core/'
import * as React from 'react'
import ListTitles from './ListTitles'
import { FixedSizeList as List } from 'react-window'
import Row, { IRowAction } from './Row'

import useStyles from './styles'
import ActivityLoader from '../ActivityLoader/ActivityLoader'

export interface IChip {
  active: boolean
  class: string
  disabledClass: string
  icon: any
}

export interface IDataTableConfig {
  listTitles: string[]
  colProps: string[]
  chips?: IChip[] | undefined
  rowHeight: number
  idProp: string
  nullText?: string
}

interface IDataTable {
  items: any[]
  config: IDataTableConfig
  handleRowClick?: (id: string, index: number) => void
  rowActions?: IRowAction[]
  loader?: boolean
  height?: number
  discrete?: boolean
}

const DataTable = React.forwardRef<HTMLDivElement, IDataTable>((props, ref) => {
  const {
    items,
    config,
    handleRowClick,
    rowActions,
    loader,
    height = 100,
    discrete
  } = props
  const {
    listTitles,
    colProps,
    chips,
    rowHeight,
    idProp,
    nullText
  } = config

  const classes = useStyles({discrete})


  // @ts-ignore
  return (
      <div className={ classes.root }>
        { listTitles && items && items.length !== 0 && (
            <>
              <ListTitles { ...{rowActions, listTitles} }   />
              <Divider/>
            </>
        ) }
        <Grid container direction="column" className={ classes.dataTableWrapper }>
          <Grid item xs>
            { loader ? (
                <Grid
                    container
                    item
                    className={ classes.loaderContainer }
                    style={ {height: height} }
                >
                  <ActivityLoader text={ 'Cargando...' }/>
                </Grid>
            ) : items?.length !== 0 ? (
                <div { ...{ref} } style={ {flexGrow: 1, height: '100%', flexBasis: 0} }>
                  {
                      <List
                        width={ '100%' }
                        height={ height }
                        itemSize={ rowHeight }
                        itemCount={ items.length }
                        itemData={ {
                          items,
                          colProps,
                          chips,
                          handleRowClick,
                          rowActions,
                          idProp,
                          nullText
                        } }>
                      { Row }
                    </List>
                  }
                </div>
            ) : (
                <>
                  { !discrete && (
                      <Box { ...{height} } py={ 2 }>
                        <Typography
                            variant="body1"
                            className={ classes.emptyListeMessage }>
                          No se encontraron elementos.
                        </Typography>
                      </Box>
                  ) }
                </>
            )

            }
          </Grid>
        </Grid>
      </div>
  )
})

export default DataTable
