import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  root: {
    height: '100%',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'scroll',
    padding: theme.spacing( 4, 2 ),

    [theme.breakpoints.up( 'md' )]: {
      justifyContent: 'center'
    }
  },

  textContainer: {
    marginBottom: theme.spacing( 2 ),

    '& h6': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body1.fontSize
    },
    [theme.breakpoints.up( 'md' )]: {
      '& h6': {
        fontSize: theme.typography.h6.fontSize
      }
    }
  },

  buttonContainer: {
    minWidth: theme.spacing( 40 ),
    margin: theme.spacing( 0.5 ),
    border: `1px solid ${ theme.palette.text.disabled }`,
    borderRadius: theme.roundness
  },


} ) )