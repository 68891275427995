import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  item: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    margin: theme.spacing( 1, 0 ),

    [theme.breakpoints.up( 'md' )]: {
      flexDirection: 'row'
    }
  },

  rootInformacionGeneral: {
    padding: theme.spacing( 4 ),
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
} ) )