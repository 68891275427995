import { API, Auth, graphqlOperation } from 'aws-amplify'

export interface GraphQLOptions<T> {
  input?: object
  variables?: T
  authMode?: any
}

export interface GraphQLResult<T = object> {
  data?: T;
  errors?: any[];
  extensions?: {
    [key: string]: any;
  };
}

export const adminQuery = async ( method: string, path: string, options?: object ) => {
  const apiName = 'AdminQueries'
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${ ( await Auth.currentSession() ).getAccessToken().getJwtToken() }`
    },
    ...options
  }

  const methodOptions = () => {
    const methods = {
      get: async () => await API.get( apiName, path, myInit ),
      post: async () => await API.post( apiName, path, myInit ),
      put: async () => await API.put( apiName, path, myInit ),
      patch: async () => await API.patch( apiName, path, myInit ),
      delete: async () => await API.del( apiName, path, myInit )
    }

    // @ts-ignore
    return methods[method]() ?? null
  }

  return await methodOptions()
}

export async function callGraphQL<T, K>(
    query: any,
    options?: GraphQLOptions<K>
): Promise<GraphQLResult<T>> {
  return ( await API.graphql(
      graphqlOperation( query, options?.variables )
  ) ) as GraphQLResult<T>
}

export const graphQLSubscription = ( subscription: string, callback: ( d: any, e: any ) => void ) => {
  // @ts-ignore
  return API.graphql( graphqlOperation( subscription ) ).subscribe( {
    next: ( { value: { data, errors } }: any ) => {
      // console.log('Subscription error: ', errors)
      callback( data, errors )
    }
  } )
}