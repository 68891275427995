import { callGraphQL } from './apiUtils'
import { GetHubQuery, GetHubQueryVariables, Hub, ListHubsQuery, ListHubsQueryVariables } from '../API'
import { getHub, listHubs } from '../graphql/queries'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'

interface IHubStorage {
  getHubs: () => Hub[] | null
  fetchHubs: () => Promise<void>,
  crearHub: ( formData: any ) => Promise<void>
  detalleHub: ( hubID: string | null ) => Promise<Hub | null>
}

let hubs: Hub[] | null = null

const HubStorage: IHubStorage = {
  getHubs: () => hubs,

  fetchHubs: async () => {
    try {
      const hubsData = await callGraphQL<ListHubsQuery, ListHubsQueryVariables>( listHubs, {
        variables: {
          limit: 2000
        }
      } )
      hubs = hubsData.data?.listHubs?.items as any[]
    } catch ( err ) {
      console.log( err )
    }
  },

  crearHub: async ( formData: any ) => {
    try {
      await API.graphql( {
        query: mutations.createHub,
        variables: {
          input: { ...formData, isArmado: false }
        }
      } )
    } catch ( e ) {
      console.log( e )
    }
  },

  detalleHub: async ( hubID: string | null ) => {
    if ( hubID ) {
      const hubDetailData = await callGraphQL<GetHubQuery, GetHubQueryVariables>( getHub, {
        variables: {
          id: hubID
        }
      } )

      return hubDetailData.data?.getHub as Hub || null
    } else {
      return null
    }
  }
}

export default HubStorage