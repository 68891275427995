import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import theme from '../../../App.theme'

const useStyles = makeStyles( {
  root: {
    maxWidth: 345,
    height: 200,
    borderRadius: theme.roundness
  },

  card: {
    height: '100%'
  }
} )

interface ICardProps {
  data: {
    label: string
    aria: string
    description: string
    action: () => void
    icon: any
  }
}

export default function ImgMediaCard( props: ICardProps ) {
  const classes = useStyles()

  return (
      <Card className={ classes.root }>
        <CardActionArea onClick={ props.data.action } classes={ { root: classes.card } }>
          <CardContent>
            { props.data.icon }
            <Typography gutterBottom variant="h5" component="h2">
              { props.data.label }
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              { props.data.description }
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
  )
}