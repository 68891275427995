import { ReducerAction } from '../contextHelpers'
import { StateElems } from './types'

export type ReducerType = 'set_children' | 'open' | 'close'

const reducer = (
    state: StateElems,
    action: ReducerAction<ReducerType, StateElems>
) => {
  const { type, payload } = action

  switch (type) {
    case 'set_children':
      return {
        ...state,
        component: payload?.component,
        title: payload?.title,
      }
    case 'open':
      return { ...state, hidden: false }
    case 'close':
      return { ...state, hidden: true }
    default:
      return state
  }
}

export default reducer
