import { useEffect, useMemo, useState } from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth } from 'aws-amplify'
import AuthStorage from '../lib/AuthStorage'
import PubSubAttachPolicy from '../lib/PubSubAttachPolicy'

interface IHookUseAuthHook {
  isGlobalAdmin: boolean
  isSignedIn: boolean
  resetAuth: () => Promise<void>
}

const useAuth = (): IHookUseAuthHook => {
  const [ authState, setAuthState ] = useState<AuthState | null>( null )
  const [ user, setUser ] = useState<object | undefined | null>( null )
  const [ isGlobalAdmin, setIsGlobalAdmin ] = useState<boolean>( false )

  const resetAuth = async () => {
    try {
      await Auth.signOut()
      setAuthState( null )
      setUser( null )
      AuthStorage.user.reset()
    } catch ( error ) {
      console.log( 'error al cerrar sesión: ', error )
    }

  }

  useEffect( () => {
    const fetchSession = async () => {
      const session = await Auth.currentSession()
      if ( session ) {
        // @ts-ignore
        const groups = session.accessToken.payload['cognito:groups']
        await PubSubAttachPolicy.attach()

        if ( groups ) {
          setIsGlobalAdmin( groups.includes( 'admins' ) )
        }
      }
    }
    if ( user ) {
      // noinspection JSIgnoredPromiseFromCall
      fetchSession()
    }
  }, [ user ] )

  useEffect( () => {
    return onAuthUIStateChange( async ( nextAuthState, authData ) => {
          if ( authData && nextAuthState === 'signedin' ) {
            // @ts-ignore
            await AuthStorage.user.set( authData.attributes.email, authData.username )
            setAuthState( nextAuthState )
            setUser( authData )
          }

        }
    )
  }, [] )

  return {
    isGlobalAdmin,

    isSignedIn: useMemo( () => {
      return Boolean( authState === AuthState.SignedIn && user )
    }, [ authState, user ] ),

    resetAuth

  }
}

export default useAuth
