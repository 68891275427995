import { Grid } from '@material-ui/core'
import * as React from 'react'

import { useStyles } from './styles'


const Layout: React.FC = ({children}) => {
  const styles = useStyles()
  return (
      <Grid container className={ styles.root }>
        <Grid item classes={ {root: styles.textFieldWrapper} }>
          { children }
        </Grid>
      </Grid>
  )
}

export default Layout
