import React from 'react'

import VistaHandler from './components/vistas/VistaHandler'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import theme from './App.theme'
import { ModalController } from './components/common'

import { Provider as ModalContextProvider } from './context/ModalContext'


const App: React.FC = () => {
  return (
      <ThemeProvider { ...{ theme } }>
        <ModalContextProvider>
          <CssBaseline/>
          <ModalController/>
          <VistaHandler/>
        </ModalContextProvider>
      </ThemeProvider>
  )
}

export default App
