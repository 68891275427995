import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import CreateHubForm from './CreateHubForm/CreateHubForm'

interface ICrearHub {
  handleCreateHub: ( formData: any ) => Promise<void>
  handleOnCancel: () => void
}

const CrearHub: FC<ICrearHub> = ( { handleCreateHub, handleOnCancel } ) => {
  const styles = useStyles()


  return (
      <Grid container item className={ styles.rootCrearHub } wrap={ 'nowrap' }>
        <Typography variant={ 'h4' }>Crear un perfil de Smart Hub.</Typography>
        <CreateHubForm onFormSubmit={ handleCreateHub } { ...{ handleOnCancel } } />
      </Grid>
  )
}
export default CrearHub
