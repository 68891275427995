import React, { FC } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

interface ISectionInformation {
  title: string
  description: string
}

const SectionInformation: FC<ISectionInformation> = ( { title, description } ) => {
  const styles = useStyles()

  return <Grid item container className={ styles.sectionInformationRoot }>
    <Grid item xs>
      <Typography variant={ 'h6' }>{ title }</Typography>
      <Divider/>
      <Typography variant={ 'body2' }>{ description }</Typography>
    </Grid>
  </Grid>
}

export default SectionInformation
