import * as React from 'react'
import { FC, useState } from 'react'

import useSchemas from './form.schema'
import useForm, { FormSchemaTextObject } from '../../../../hooks/useForm'
import { Boton, FormTextInput, SearchFilter } from '../../../common'
import appConfig from '../../../../appConfig.json'
import { Grid } from '@material-ui/core'

import useStyles from './styles'
import SectionInformation from '../../../common/SectionInformation/SectionInformation'
import { Box } from '@material-ui/core/'

type InputName =
    'descripcion'
    | 'codigoEmisor'
    | 'codigoReceptor'
    | 'protocoloReceptor'
    | 'pulselengthReceptor'
    | 'mqttTopic'
    | 'mqttPayload'

interface IInput {
  name: InputName,
  disabled: boolean
  label: string
}

export interface CreateHubFormSchemaData {
  descripcion: FormSchemaTextObject
  codigoEmisor: FormSchemaTextObject
  codigoReceptor: FormSchemaTextObject
  protocoloReceptor: FormSchemaTextObject
  pulselengthReceptor: FormSchemaTextObject
  mqttTopic: FormSchemaTextObject
  mqttPayload: FormSchemaTextObject
}

interface CreateHubFormProps {
  handleOnCancel: () => void
  onFormSubmit:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
}

const CreateConexionForm: FC<CreateHubFormProps> = ( { handleOnCancel, onFormSubmit } ) => {
  const { initialState, validationStateSchema } = useSchemas()
  const styles = useStyles()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useForm<CreateHubFormSchemaData>(
      initialState,
      validationStateSchema,
      async cleanData => await onFormSubmit( { ...cleanData, tipo: selectedDropdownOption.prop } )
  )


  const [ selectedIndicadorIndex, setSelectedIndicadorIndex ] = useState( 0 )

  const handleSelectItem = ( index: number ) => {
    setSelectedIndicadorIndex( index )
  }

  const configSearchList = {
    data: appConfig.tipoDeConexion,
    idProp: 'nombre'
  }

  const selectedDropdownOption = configSearchList.data[selectedIndicadorIndex]

  const generalInfoInputs: IInput[] = [
    {
      name: 'descripcion',
      label: 'Descripción *',
      disabled: false
    },
    {
      name: 'codigoEmisor',
      label: 'Código emisor *',
      disabled: false
    },
    {
      name: 'codigoReceptor',
      label: 'Código receptor',
      disabled: selectedDropdownOption.prop === 'ESPECIAL' || selectedDropdownOption.prop === 'MQTT'
    },
    {
      name: 'protocoloReceptor',
      label: 'Protocolo receptor',
      disabled: selectedDropdownOption.prop === 'ESPECIAL' || selectedDropdownOption.prop === 'MQTT'
    },
    {
      name: 'pulselengthReceptor',
      label: 'Pulselength receptor',
      disabled: selectedDropdownOption.prop === 'ESPECIAL' || selectedDropdownOption.prop === 'MQTT'
    },
    {
      name: 'mqttTopic',
      label: 'Tópico MQTT',
      disabled: selectedDropdownOption.prop !== 'MQTT'
    },
    {
      name: 'mqttPayload',
      label: 'Payload MQTT',
      disabled: selectedDropdownOption.prop !== 'MQTT'
    }
  ]

  return (
      <Grid container className={ `${ styles.formContainer } ${ styles.modalForm }` }>
        <SectionInformation title={ 'Información general del Smart Hub' } description={ 'El Smart Hub ID deberá de' +
        ' coincidir con el ID de creación del dispositivo en la nube.' }/>
        <Grid item container direction={ 'column' }>
          {
            generalInfoInputs.map( ( input => (
                <Grid key={ input.name } item className={ styles.inputItem }>
                  <FormTextInput
                      required
                      disabled={ input.disabled }
                      name={ input.name }
                      label={ input.label }
                      value={ updatedData[input.name].value }
                      errorValue={ updatedData[input.name].error }
                      onChange={ handleUpdateData }
                  />
                </Grid>
            ) ) )
          }
        </Grid>

        <SectionInformation title={ 'Tipo de conexión' } description={ 'Selecciona el tipo de conexión que crearás' }/>

        <SearchFilter
            removeSearch
            value={ selectedDropdownOption.nombre }
            placeholder={ 'Buscar indicador' }
            listConfig={ configSearchList }
            { ...{ handleSelectItem } } />

        <Grid item xs container className={ styles.submit }>
          <Box mr={ 2 }>
            <Boton discrete small aria={ 'cancelar' } handleClick={ handleOnCancel }>
              Cancelar
            </Boton>
          </Box>
          <Boton
              small
              aria={ 'crear smart hub' }
              handleClick={ handleOnSubmit }
              isLoading={ isButtonLoading }
              disabled={ !buttonState }>
            Confirmar
          </Boton>
        </Grid>
      </Grid>

  )
}

export default CreateConexionForm
