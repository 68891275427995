import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../../App.theme'


export default makeStyles( ( theme: ITheme ) => ( {
  textRoot: {
    margin: theme.spacing( 2, 1 ),
    '& h6': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold
    },

    [theme.breakpoints.up( 'md' )]: {
      '& h6': {
        fontSize: theme.typography.h6.fontSize
      }
    }
  },

  subtitle: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    margin: theme.spacing( 4, 0, 2, 0 ),

    [theme.breakpoints.up( 'md' )]: {
      flexDirection: 'row'
    }
  },

  controlesRoot: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },


  rootItemList: {
    padding: theme.spacing( 4 )
  }
} ) )