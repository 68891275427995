import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const FormTextInput = ({ children, textArea, helper }) => {
  const { formItemLayoutRoot, textFieldWrapper, helperClass } = useStyles({
    textArea,
  });
  return (
    <Grid container className={formItemLayoutRoot}>
      <Grid item classes={{ root: textFieldWrapper }}>
        {children}
      </Grid>
      {helper && (
        <Typography className={helperClass} variant="caption" align="right">
          {helper}
        </Typography>
      )}
    </Grid>
  );
};

export default FormTextInput;
