import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { StateUpdater } from '../VistaHandler'
import { Card } from '../../common'
import AuthStorage from '../../../lib/AuthStorage'
import { FcPrivacy, GoGithubAction } from 'react-icons/all'

interface IVistaLobby {
  stateUpdater: StateUpdater
  isGlobalAdmin: boolean
}

const VistaLobby: FC<IVistaLobby> = ( { isGlobalAdmin, stateUpdater } ) => {
  const styles = useStyles()
  const user = AuthStorage.user.get()


  const modulosDisponibles = () => {
    switch ( user?.tipo ) {
      case 'USUARIO':
        return {
          modulos: [
            {
              aria: 'Smart Hub',
              action: async () => stateUpdater.setEtapa( 'usuario' ),
              label: 'Smart Hub',
              description: 'Activa o desactiva Secure Smart Hub.',
              icon: <GoGithubAction size={ 40 }/>
            }
          ],
          bienvenida: `Hola, ${ user?.nombre }`
        }
      default:
        if ( isGlobalAdmin ) {
          return {
            modulos: [ {
              aria: 'Smart Hubs',
              action: async () => stateUpdater.setEtapa( 'hubs' ),
              label: 'Smart Hubs',
              description: 'Gestión de Smart Hubs, clientes y contactos de emergencia.',
              icon: <GoGithubAction size={ 40 }/>
            } ],
            bienvenida: `Hola, Admin de Smart Hubs.`
          }
        } else {
          return {
            modulos: [],
            bienvenida: `Usuario no identificado`
          }
        }

    }
  }

  const mods = modulosDisponibles()

  const buttonConfig = [
    ...mods.modulos,
    {
      aria: 'cerrar sesión',
      action: async () => await stateUpdater.resetAuth(),
      label: 'Cerrar sesión',
      description: 'Tu sesión quedará abierta en este navegador, cierra sesión si ya no vas a utilizar la app.',
      icon: <FcPrivacy size={ 40 }/>
    }
  ]

  return (
      <Grid container className={ styles.root } direction={ 'column' }>
        <Grid item className={ styles.textContainer }>
          <Typography variant={ 'h6' } align={ 'center' }>{ mods.bienvenida } <br/> Panel Principal</Typography>
        </Grid>
        <Grid container alignItems={ 'center' } justifyContent={ 'center' }>
          {
            buttonConfig.map( ( e, i ) => {
              return (
                  <Grid key={ i } item className={ styles.buttonContainer }>
                    <Card data={ e }/>
                  </Grid>
              )
            } )
          }
        </Grid>

      </Grid>
  )

}

export default VistaLobby
