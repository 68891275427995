import React, { useRef, useState } from 'react'

import {
  Grid,
  Typography,
  Popper,
  Paper,
  Grow,
  ClickAwayListener
} from '@material-ui/core'
import { BiChevronDown } from 'react-icons/bi'
import FormItemLayout from './FormItemLayout/FormItemLayout'
import SearchList from './SearchList/SearchList'

import { FormMenuMask, useStyles } from './styles'


const SearchFilter = ( {
                         value,
                         listConfig,
                         handleSelectItem,
                         placeholder,
                         removeSearch
                       } ) => {
  const { labelClass, arrow, paper, popper } = useStyles()
  const anchorRef = useRef( null )
  const [ open, setOpen ] = useState( false )

  const handleToggle = () => {
    setOpen( ( prevOpen ) => !prevOpen )
  }

  const handleClose = ( event ) => {
    if ( anchorRef.current && anchorRef.current.contains( event.target ) ) {
      return
    }
    setOpen( false )
  }

  const handleSelection = ( result ) => {
    setOpen( false )
    const itemIndex = listConfig.data.findIndex( ( e ) => e[listConfig.idProp] === result[listConfig.idProp] )
    handleSelectItem( itemIndex )
  }

  return (
      <>
        <FormItemLayout>
          <FormMenuMask onClick={ handleToggle } ref={ anchorRef }>
            <Grid container>
              <Grid item xs container alignItems={ 'center' }>
                <Typography className={ labelClass } variant="body2">
                  { value }
                </Typography>
              </Grid>
              <Grid item xs={ 1 }>
                <BiChevronDown className={ arrow }/>
              </Grid>
            </Grid>
          </FormMenuMask>
        </FormItemLayout>
        <Popper
            className={ popper }
            open={ open }
            anchorEl={ anchorRef.current }
            transition
            disablePortal
        >
          { ( { TransitionProps, placement } ) => (
              <Grow
                  { ...TransitionProps }
                  style={ {
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                  } }
              >
                <Paper className={ paper }>
                  <ClickAwayListener onClickAway={ handleClose }>
                    <div>
                      <SearchList
                          handleSelectItem={ handleSelection }
                          { ...{ placeholder, listConfig, removeSearch } }
                      />
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          ) }
        </Popper>
      </>
  )
}

export default SearchFilter
