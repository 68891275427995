import { useCallback, useEffect, useReducer, useState } from 'react'
import useLoadingButton from './useLoadingButton'

export interface FormEvent {
  target?: {
    name: string
    value: any
    id?: number
  }
  preventDefault?: () => void
}

export interface FormSchemaValidation {
  required?: boolean
  equalsField?: {
    field: string
    error: string
  }
  validator?: {
    regEx: RegExp
    error: string
  }
}

export interface FormSchemaTextObject {
  value: string
  error: string
}

interface FormUpdateHook<T> {
  updatedData: T
  handleUpdateData: (e: FormEvent) => Promise<void>
  buttonState: boolean
  handleOnSubmit: () => void
  isButtonLoading: boolean
  resetData: () => void
}

const useForm = <T>(
    initialState: Object,
    validationSchema: any = {},
    callback: (...args: any[]) => Promise<void> = async () => {
    }
): FormUpdateHook<T> => {
  const [isButtonLoading, setButtonLoader] = useLoadingButton()

  useEffect(() => {
    return () => {
      setButtonLoader(false)
    }
  }, [setButtonLoader])

  const [updatedData, setUpdatedData] = useReducer(
      (state: any, newState: any) => ({...state, ...newState}),
      initialState
  )

  const [cleanData, setCleanData] = useReducer(
      (state: any, newState: any) => ({...state, ...newState}),
      {}
  )

  const [notValid, setNotValid] = useState<boolean>(false)
  const [buttonState, setButtonState] = useState<boolean>(false)

  const validateState = useCallback(() => {
    return Object.keys(validationSchema).some(key => {
      // @ts-ignore
      const isInputFieldRequired = validationSchema[key].required
      const stateValue = updatedData[key].value
      const stateError = updatedData[key].error

      return (isInputFieldRequired && !stateValue) || stateError
    })
  }, [updatedData, validationSchema])

  useEffect(() => {
    if (notValid) {
      setButtonState(!validateState())
    }
  }, [updatedData, notValid, validateState, setButtonState])

  const handleUpdateData = useCallback(
      async (e: FormEvent) => {
        // @ts-ignore
        const {name, value, id} = e.target
        setNotValid(true)

        let error = ''
        if (validationSchema[name].required && !value) {
          error = 'Campo requerido'
        }
        if (
            !validationSchema[name].validator &&
            validationSchema[name].equalsField
        ) {
          if (
              value !== updatedData[validationSchema[name].equalsField.field].value
          ) {
            error = validationSchema[name].equalsField.error
          }

          setUpdatedData({[name]: {value, error, id}})
          setCleanData({[name]: value})
        }

        if (
            validationSchema[name].validator !== null &&
            typeof validationSchema[name].validator === 'object'
        ) {
          if (value && !validationSchema[name].validator.regEx.test(value)) {
            error = validationSchema[name].validator.error
          }
        }

        setUpdatedData({[name]: {value, error, id}})
        setCleanData({[name]: value})
      },
      [validationSchema, updatedData]
  )

  const handleOnSubmit = useCallback(async () => {
    setButtonLoader(true)
    setButtonState(false)

    if (!validateState()) {
      try {
        await callback(cleanData)
      } catch {
      }

      setButtonLoader(false)
    }
  }, [cleanData, callback, validateState, setButtonLoader])

  const resetData = useCallback(() => {
    setUpdatedData(initialState)
  }, [initialState])

  return {
    updatedData,
    handleUpdateData,
    buttonState,
    handleOnSubmit,
    isButtonLoading,
    resetData
  }
}


export default useForm