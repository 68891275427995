import { FormSchemaTextObject, FormSchemaValidation } from '../../../../hooks/useForm'

interface IFormType<T> {
  id: T
}

interface Schema {
  initialState: IFormType<FormSchemaTextObject>
  validationStateSchema: IFormType<FormSchemaValidation>
}

const CreateUserSchema = (): Schema => ( {
  initialState: {
    id: { value: '', error: '' },
  },
  validationStateSchema: {
    id: { required: true },
  }
} )


export default CreateUserSchema