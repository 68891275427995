import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  searchboxRoot: {
    overflow: 'scroll',
    [theme.breakpoints.up( 'md' )]: {}
  },

  searchList: {
    maxHeight: '100%',
    [theme.breakpoints.up( 'md' )]: {}
  },

  searchResultItem: {
    margin: theme.spacing( 1, 0 ),
    cursor: 'pointer',
    '& p': {
      fontSize: theme.typography.body2.fontSize
    }
  }
}) )