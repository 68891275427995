import { FormSchemaTextObject, FormSchemaValidation } from '../../../hooks/useForm'


interface IContactFormType<T> {
  confirmationBase: T
  confirmation: T,
}

interface Schema {
  initialState: IContactFormType<FormSchemaTextObject>
  validationStateSchema: IContactFormType<FormSchemaValidation>
}

const ConfirmationSchema = (): Schema => ( {
  initialState: {
    confirmationBase: { value: 'de acuerdo', error: '' },
    confirmation: { value: '', error: '' },
  },
  validationStateSchema: {
    confirmationBase: {},
    confirmation: {
      required: true,
      equalsField: {
        field: 'confirmationBase',
        error: 'El texto no coincide',
      },
    },
  }
} )


export default ConfirmationSchema
