import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../../App.theme'

export default makeStyles((theme: ITheme) => ({
  inputItem: {
    margin: theme.spacing(0,0.5)
  },

  submit: {
    padding: theme.spacing(0, 2)
  }
}))