import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  sectionInformationRoot: {

    margin: theme.spacing( 2, 0 ),
    '& p': {
      margin: theme.spacing( 1, 0 )
    },

    [theme.breakpoints.up( 'md' )]: {}
  }
} ) )

export {}