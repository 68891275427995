import React, { FC, ReactNode } from 'react'
import { SideMenu } from '../../../common'
import { ISideMenuCategory, ISideMenuControls } from '../../../common/SideMenu/SideMenu'
import { AiOutlineHome, BiArrowBack, BiLogOut } from 'react-icons/all'
import { IHubsNav } from '../../VistaHubs/VistaHubs'
import { StateUpdater } from '../../VistaHandler'

interface IMainView {
  hubsNav: IHubsNav
  stateUpdater: StateUpdater
  subview: ReactNode
}

const MainView: FC<IMainView> = ( { subview, hubsNav, stateUpdater } ) => {
  const subviewName = hubsNav.getCurrentSubview()
  const isSubviewPrincipal = subviewName === 'lista'

  const mainViewMenuItems: ISideMenuCategory[] = [
    {
      title: 'Hubs',
      items: [
        {
          title: 'Todos los hubs',
          component: subview
        }
      ]
    }
  ]

  const navTitle = isSubviewPrincipal ? 'Inicio' : 'Atrás'

  const mainViewControls: ISideMenuControls[] = [
    {
      tooltip: navTitle,
      ariaLabel: navTitle,
      action: isSubviewPrincipal
          ? () => stateUpdater.setEtapa( 'lobby' )
          : () => hubsNav.setSubview( 'lista' ),
      icon: isSubviewPrincipal ? <AiOutlineHome/> : <BiArrowBack/>
    },
    {
      tooltip: 'cerrar sesión',
      ariaLabel: 'cerrar sesión',
      action: () => stateUpdater.resetAuth(),
      icon: <BiLogOut/>
    }
  ]
  return <SideMenu menuItems={ mainViewMenuItems } controls={mainViewControls} />

}

export default MainView
