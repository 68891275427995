import React, { FC, ReactNode, useState } from 'react'
import {
  Badge,
  Divider,
  Grid,
  GridSize, IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader, Tooltip,
  Typography
} from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import appConfig from '../../../appConfig.json'

import useStyles from './styles'
import { CgClose, FcMenu } from 'react-icons/all'

export interface ISideMenuActivePosition {
  section: number,
  item: number
}

interface ISideMenuItem {
  title: string
  component: ReactNode
  renders?: boolean
  disabled?: boolean
  action?: () => void
  showBadge?: boolean
  onSelection?: ( { section, item }: ISideMenuActivePosition ) => void
}

export interface ISideMenuCategory {
  title: string
  items: ISideMenuItem[]
  renders?: boolean
}

interface ISideMenuProps {
  menuItems: ISideMenuCategory[]
  controls?: ISideMenuControls[]
}

export interface ISideMenuControls {
  tooltip: string
  ariaLabel: string
  action: () => void
  icon: any
}

const NAV_SIZE: boolean | GridSize | undefined = appConfig.navSize as GridSize

const SideMenu: FC<ISideMenuProps> = ( { menuItems, controls } ) => {
  const [ isMenuVisible, setIsMenuVisible ] = useState( !isMobile )
  const styles = useStyles( { isMenuVisible } )
  const [ { section, item }, setActiveComponent ] = useState( {
    section: 0,
    item: 0
  } )


  const sideMenuControls: ISideMenuControls[] = [
    ...( controls ? controls : [] ),
    {
      tooltip: `${ isMenuVisible ? 'Cerrar' : 'Abrir' } menú`,
      ariaLabel: 'menú',
      action: () => setIsMenuVisible( prevState => !prevState ),
      icon: isMenuVisible ? <CgClose/> : <FcMenu/>
    }
  ]


  return (
      <Grid container item xs className={ styles.sideMenuRoot }>

        <Grid item className={ styles.menuBtn }>
          {
            sideMenuControls.map( ( e, i ) => (
                <Grid item key={ `${ e.tooltip }-${ i }` }>
                  <Tooltip title={ e.tooltip }>
                    <IconButton size={ 'small' } aria-label={ e.ariaLabel }
                                onClick={ e.action }>
                      { e.icon }
                    </IconButton>
                  </Tooltip>
                </Grid>
            ) )
          }
        </Grid>
        {
          ( <Grid item md={ NAV_SIZE } className={ styles.leftPaneRoot }>
            <List component="nav" subheader={ <div/> } className={ styles.leftPaneContent }>
              { menuItems.map(
                  ( { title, items, renders = true }, i ) =>
                      renders && (
                          <div key={ i }>
                            <ListSubheader component="div">

                              <Typography variant="caption" className={ styles.sectionTitle }>
                                { title }
                              </Typography>


                            </ListSubheader>
                            { items.map(
                                ( { title, disabled, action, renders = true, showBadge, onSelection }, j ) =>
                                    renders && (

                                        <ListItem
                                            key={ j }
                                            dense
                                            button
                                            disabled={ disabled }
                                            onClick={ () => {
                                              if ( isMobile ) {
                                                setIsMenuVisible( false )
                                              }

                                              if ( onSelection ) {
                                                onSelection( { section: i, item: j } )
                                              }

                                              if ( action ) {
                                                action()
                                              } else {
                                                setActiveComponent( { section: i, item: j } )
                                              }
                                            } }
                                            className={
                                              section === i && item === j
                                                  ? styles.activeListItemContainer
                                                  : ''
                                            }
                                        >

                                          <ListItemText
                                              classes={ {
                                                primary:
                                                    section === i && item === j
                                                        ? styles.activeListItem
                                                        : styles.listItem
                                              } }
                                              primary={ <Badge invisible={ !showBadge } variant={ 'dot' } badgeContent={ '' }
                                                               color="error" anchorOrigin={ {
                                                vertical: 'top',
                                                horizontal: 'right'
                                              } }>
                                                { title }
                                              </Badge> }
                                          />
                                        </ListItem>
                                    )
                            ) }
                            <Divider className={ styles.sectionDivider }/>
                          </div>
                      )
              ) }
            </List>
          </Grid> )
        }

        <Grid item xs style={ { height: '100%' } }>
          <main className={ styles.content }>
            { menuItems && menuItems[section].items[item].component }
          </main>
        </Grid>
      </Grid>
  )
}

export default SideMenu
