import {
  Conexion,
  ConexionByHubQuery,
  ConexionByHubQueryVariables,
  GetConexionQuery,
  GetConexionQueryVariables
} from '../API'
import { callGraphQL } from './apiUtils'
import { conexionByHub, getConexion } from '../graphql/queries'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'

let conexiones: Conexion[] | null = null

interface IConexionesStorage {
  conexiones: {
    getConexiones: () => Conexion[] | null
    fetchConexiones: ( idHub: string ) => Promise<void>,
    crearConexion: ( formData: any, idHub: string ) => Promise<void>
    borrarConexion: ( idConexion: string ) => Promise<void>
    detalleConexion: ( idConexion: string ) => Promise<Conexion>
  }
}

const ConexionesStorage: IConexionesStorage = {
  conexiones: {
    getConexiones() {
      return conexiones
    },

    async fetchConexiones( idHub: string ) {
      try {
        const conexionesData = await callGraphQL<ConexionByHubQuery, ConexionByHubQueryVariables>(
            conexionByHub, {
              variables: {
                idHub
              }
            } )

        conexiones = conexionesData?.data?.conexionByHub?.items as any[]
      } catch ( e ) {
        console.log( e )
      }
    },

    async crearConexion( formData: any, idHub: string ) {
      try {
        await API.graphql( {
          query: mutations.createConexion,
          variables: {
            input: { ...formData, idHub }
          }
        } )
      } catch ( e ) {
        console.log( e )
      }
    },

    async borrarConexion( idConexion: string ) {
      try {
        await API.graphql( {
          query: mutations.deleteConexion,
          variables: {
            input: { id: idConexion }
          }
        } )
      } catch ( e ) {
        console.log( e )
      }
    },

    async detalleConexion( idConexion: string ) {
      const conexionDetailData = await callGraphQL<GetConexionQuery, GetConexionQueryVariables>( getConexion, {
        variables: {
          id: idConexion
        }
      } )

      console.log(conexionDetailData)

      return conexionDetailData.data?.getConexion as Conexion || null
    }
  }
}

export default ConexionesStorage