import React, { FC, useEffect, useState } from 'react'

import appConfig from '../../appConfig.json'
import EtapaConfig from './EtapaConfig'
import { VistaLobby, VistaHubs } from './index'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { useAuth } from '../../hooks'
import VistaUsuario from './VistaUsuario/VistaUsuario'

const etapaInicial = appConfig.isDebug
    ? appConfig.estapaIniEnModoDebug
    : EtapaConfig.LOBBY

export interface IVistaNav<T, K, R> {
  setComponente: ( c: T, payload?: K ) => void
  setSubview: ( subview: R ) => void
  getCurrentSubview: () => R
}

interface IVistaHandlerProps {
}

export interface StateUpdater {
  setEtapa: ( e: string ) => void
  resetAuth: () => Promise<void>
}

const VistaHandler: FC<IVistaHandlerProps> = () => {

  const [ etapa, setEtapa ] = useState( etapaInicial )
  const { isSignedIn, isGlobalAdmin, resetAuth } = useAuth()

  useEffect( () => {
    setEtapa( EtapaConfig.LOBBY )
  }, [ isSignedIn ] )


  const stateUpdater: StateUpdater = {
    setEtapa: ( e: string ) => setEtapa( e ),
    resetAuth: async () => await resetAuth()
  }

  const viewHandler = () => {
    if ( !isSignedIn ) {
      return (
          <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp/>
          </AmplifyAuthenticator>
      )
    }

    switch ( etapa ) {
      case EtapaConfig.LOBBY:
        return <VistaLobby { ...{ stateUpdater, isGlobalAdmin } } />

      case EtapaConfig.HUBS:
        return <VistaHubs { ...{ stateUpdater } } />

      case EtapaConfig.USUARIO:
        return <VistaUsuario { ...{ stateUpdater } } />

      default:
        return <p>{ etapa }</p>
    }
  }

  return <>{ viewHandler() }</>
}

export default VistaHandler
