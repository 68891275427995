import { FormSchemaTextObject, FormSchemaValidation } from '../../../../hooks/useForm'

interface IContactFormType<T> {
  nombre: T
  email: T
  telefono: T
}

interface Schema {
  initialState: IContactFormType<FormSchemaTextObject>
  validationStateSchema: IContactFormType<FormSchemaValidation>
}

const rgx = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  phone: /^\+[1-9]{2}[0-9]{10}$/
}

const CreateUserSchema = (): Schema => ( {
  initialState: {
    nombre: { value: '', error: '' },
    email: { value: '', error: '' },
    telefono: { value: '', error: '' }

  },
  validationStateSchema: {
    nombre: { required: true },
    email: {
      required: true,
      validator: {
        regEx: rgx.email,
        error: 'Favor de introducir un correo válido.'
      }
    },
    telefono: {
      required: false,
      validator: {
        regEx: rgx.phone,
        error: 'Favor de introducir un teléfono con formato completo: +5255XXXXXXXX'
      }
    }
  }
} )


export default CreateUserSchema