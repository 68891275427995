import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../../App.theme'

interface IStyleProps {
  buttonState: boolean
}

export default makeStyles((theme: ITheme) => ({
  textWrapper: {
    margin: theme.spacing(1, 0),
    justifyContent: 'flex-start',
  },
  confirmationText: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.h6.fontSize,
    textAlign: 'left',
  },
  buttonWarning: {
    textTransform: 'none',
    background: ({ buttonState }: IStyleProps) =>
      !buttonState
        ? theme.palette.text.superLight
        : theme.palette.status.danger,
    color: ({ buttonState }: IStyleProps) =>
      !buttonState ? theme.palette.text.disabled : theme.palette.text.lightText,
    marginRight: theme.spacing(3),
    '&:hover': {
      background: `${theme.palette.status.danger}`,
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  helperWrapper: {
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  helperText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));
