import React, { FC, useEffect, useRef } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import CreateUserForm from './CreateUserForm/CreateUserForm'
import useHooksHelpers, { ITableConfig } from '../../../hooks/useHooskHelpers'
import { useSize } from '../../../hooks'
import DataTable from '../../common/DataTable/DataTable'
import { Boton } from '../../common'
import { HiRefresh } from 'react-icons/hi'

interface IGestion {
  title: string
  onDeleteUser:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  onCreateUser:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  fetchUsers:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  users: any[]
  isLoading: boolean
}

const Gestion: FC<IGestion> = ( {
                                  title,
                                  onDeleteUser,
                                  onCreateUser,
                                  fetchUsers,
                                  users,
                                  isLoading
                                } ) => {
  const styles = useStyles()

  useEffect( () => {
    if ( users.length === 0 ) {
      fetchUsers()
    }
    // eslint-disable-next-line
  }, [ title ] )

  const dataTableConfig: ITableConfig[] = [
    { id: 0, field: 'Nombre', colProp: 'nombre' },
    { id: 1, field: 'Correo', colProp: 'email' },
  ]

  const { listTitles, colProps } = useHooksHelpers( {
    dataTableConfig
  } )

  const wrapperRef = useRef( null )
  const { height } = useSize( wrapperRef )


  return (
      <Grid container className={ styles.rootGestion } direction={ 'column' }>

        <Grid item container className={ `${ styles.controlesRoot } ${ styles.controlsContainer }` }>
          <Grid item xs>
            <Typography variant={ 'h6' }>{ title }</Typography>
          </Grid>
          <Grid item>
            <Boton small handleClick={ fetchUsers } aria={ 'Crear grupo' }>
              <Grid container justifyContent={ 'center' } alignItems={ 'center' }><HiRefresh/></Grid>
            </Boton>
          </Grid>
        </Grid>


        <Grid item container className={ styles.userCreationContainer }>
          <CreateUserForm onFormSubmit={ onCreateUser }/>
        </Grid>

        <Grid item container xs>
          <div style={ { flexGrow: 1, flexBasis: 0 } } ref={ wrapperRef }>
            <DataTable
                loader={ isLoading }
                items={ users }
                config={ {
                  listTitles,
                  colProps,
                  idProp: 'username',
                  rowHeight: 60
                } }
                height={ height && height - 60 }
                rowActions={ [
                  {
                    title: 'Eliminar',
                    action: onDeleteUser,
                    disabled: false
                  }
                ] }
            />
          </div>

        </Grid>


      </Grid>
  )
}
export default Gestion
