import React, { FC } from 'react'

import { Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import theme from '../../../App.theme'

import useStyles from './styles'


interface ModalMessageProps {
  text: string
}


const ModalMessage: FC<ModalMessageProps> = ( { text } ) => {
  const { textWrapper, confirmationText } = useStyles()

  return (
      <Grid container style={ {
        maxWidth: 500,
        padding: theme.spacing( 2 ),
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      } }>
        <Grid container className={ textWrapper }>
          <Typography className={ confirmationText }>{ text }</Typography>
        </Grid>
      </Grid>
  )
}

export default ModalMessage
