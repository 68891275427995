import { Grid } from '@material-ui/core/'
import * as React from 'react'
import { FC } from 'react'
import theme from '../../../App.theme'
import { IRowAction } from './Row'

import useStyles from './styles'

import Title from './Title'

interface IListTitles {
  listTitles: string[]
  rowActions?: IRowAction[]
}

const ListTitles: FC<IListTitles> = ({
                                       listTitles,
                                       rowActions
                                     }) => {
  const classes = useStyles({})

  return (
      <Grid container className={ classes.titleWrapper }>
        { listTitles.map((title: string, index: number) => (
            <Grid key={ index } container item xs style={ {padding: 2} }>
              <Grid item xs>
                <Title
                    title={ title }
                />
              </Grid>
              <Grid
                  item
                  container
                  alignItems="center"
                  style={ {
                    marginRight: theme.spacing(1),
                    width: 'auto'
                  } }
              >
              </Grid>
            </Grid>
        )) }
        { rowActions && <Title title="Acciones"/> }
      </Grid>
  )
}

export default ListTitles
