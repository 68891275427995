import React, { FC, useRef } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import useStyles from '../../VistaHubs/styles'
import useHooksHelpers, { ITableConfig } from '../../../../hooks/useHooskHelpers'

import { useSize } from '../../../../hooks'
import DataTable from '../../../common/DataTable/DataTable'
import { Boton } from '../../../common'

export interface  ILog {
  categoria: string
  tipo: string
  code: string
  timestamp: string
}

interface ILogs {
  title: string
  logs: ILog[]
  onCleanData: () => void
}

const Logs: FC<ILogs> = ( { title, logs,onCleanData } ) => {
  const styles = useStyles()


  const dataTableConfig: ITableConfig[] = [
    { id: 0, field: 'Categoría', colProp: 'categoria' },
    { id: 1, field: 'Tipo', colProp: 'tipo' },
    { id: 2, field: 'Código', colProp: 'code' },
    { id: 3, field: 'Hora', colProp: 'timestamp' },

  ]

  const { listTitles, colProps } = useHooksHelpers( {
    dataTableConfig
  } )

  const wrapperRef = useRef( null )
  const { height } = useSize( wrapperRef )

  return (
      <Grid container className={ styles.rootGestion } direction={ 'column' }>
        <Grid item container className={ `${ styles.controlesRoot } ${ styles.controlsContainer }` }>
          <Grid item xs container direction={ 'column' }>
            <Typography variant={ 'h6' }>{ title }</Typography>
          </Grid>
          <Box mr={ 2 }>
            <Boton small handleClick={ onCleanData } aria={ 'refresh' }>
              <Grid container justifyContent={ 'center' } alignItems={ 'center' }>Limpiar log</Grid>
            </Boton>
          </Box>
        </Grid>

        <Grid item container xs className={ styles.dataTableContainer }>
          <div style={ { flexGrow: 1, flexBasis: 0 } } ref={ wrapperRef }>
            <DataTable
                loader={ false }
                items={ logs }
                config={ {
                  listTitles,
                  colProps,
                  idProp: 'id',
                  rowHeight: 60
                } }
                height={ height && height - 60 }
            />
          </div>

        </Grid>


      </Grid>
  )
}
export default Logs
