import { useEffect } from 'react'
import * as React from 'react'

import {
  Backdrop,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton
} from '@material-ui/core'

import useStyles from './styles'
import { FaTimes } from 'react-icons/fa'

interface IModalProps {
  title: string
  open: boolean
  onClose: ( () => void ) | ( () => Promise<void> )
}

const ModalComponent: React.FC<IModalProps> = ( {
                                                  children,
                                                  title,
                                                  open,
                                                  onClose = () => {
                                                  }
                                                } ) => {
  const {
    root,
    paper,
    card,
    cardContent,
    action,
    header,
    titleClass,
    closeButton
  } = useStyles()

  // @ts-ignore
  useEffect( () => {
    document.body.style.overflow = 'hidden'
    return () => ( document.body.style.overflow = 'unset' )
  }, [] )


  return (
      <Modal
          aria-labelledby="modal"
          aria-describedby="modal"
          className={ root }
          open={ open }
          onClose={ onClose }
          closeAfterTransition
          BackdropComponent={ Backdrop }
          BackdropProps={ {
            timeout: 500
          } }
      >
        <div className={ paper }>
          <Card className={ card }>
            { title && <CardHeader
                classes={ { action: action, root: header, title: titleClass } }
                title={ title }
                action={
                  <IconButton
                      size="small"
                      className={ closeButton }
                      aria-label="Cerrar"
                      onClick={ onClose }
                  >
                    <FaTimes/>
                  </IconButton>
                }
            /> }
            <Divider/>
            <CardContent classes={ { root: cardContent } }>{ children }</CardContent>
          </Card>
        </div>
      </Modal>
  )
}

export default ModalComponent
