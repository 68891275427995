// noinspection ExceptionCaughtLocallyJS

import { Chip } from '@material-ui/core'
import moment from 'moment'

import { Grid, ListItem, ListItemText } from '@material-ui/core/'
import * as React from 'react'
import { FC, Fragment, useMemo, useState } from 'react'

import { nestedChecker } from './nestedChecker'
import separadorMiles from './separadorMiles'
import useStyles from './styles'
import { IChip } from './DataTable'
import ButtonComponent from '../Boton/Boton'

export interface IRowAction {
  title: string
  action: ( id: number, index: number ) => void
  disabled?: boolean
}

export interface IDataRow {
  items: any
  colProps: string[]
  chips?: IChip[]
  handleRowClick?: ( id: string, index: number ) => void
  rowActions?: IRowAction[]
  idProp: string
  nullText?: string
}

export interface IRow {
  index: number
  style: Object
  data: IDataRow
}

const Row: FC<IRow> = ( { index, style, data } ) => {

  const {
    items,
    colProps,
    chips = [],
    handleRowClick,
    rowActions,
    idProp,
    nullText
  } = data

  const classes = useStyles( {} )

  const [ loading, setLoading ] = useState( false )
  const [ activeActionIndex, setActiveActionIndex ] = useState<number | null>( null )


  const id = useMemo( () => nestedChecker( idProp, index, items ), [
    idProp,
    index,
    items
  ] )

  const handleRowActionClick = async ( disabled: boolean | undefined, action: ( id: number, index: number ) => void ) => {
    if ( !disabled ) {
      await action( id, index )
    }
  }

  const discardDate = ( receivedContent: string | number ) => {
    try {
      const date = Date.parse( receivedContent.toString() )
      if ( date ) {
        return moment( date ).format( 'lll' )
      } else {
        throw new Error( 'No number parsing' )
      }
    } catch {
      return separadorMiles( receivedContent )
    }

  }

  return (
      <Fragment>
        { items[index] && (
            <ListItem
                classes={ { root: index % 2 === 0 ? classes.listItemRow : '' } }
                style={ style }
                key={ index }
                onClick={ () =>
                    !rowActions && handleRowClick ? handleRowClick( id, index ) : null
                }
            >
              <Grid container>
                { colProps.map( ( col, i ) => {
                  const receivedContent = nestedChecker( col, index, items )
                  return (
                      <Grid item xs key={ `${ i }${ col }` } className={ classes.item }>
                        { chips[i] ? (
                            <Chip
                                classes={ {
                                  // @ts-ignore
                                  root: chips[i]['class'],
                                  // @ts-ignore
                                  disabled: chips[i]['disabledClass']
                                } }
                                color="primary"
                                size="small"
                                icon={ // @ts-ignore
                                  chips[i]['icon'] }
                                label={ receivedContent }
                                disabled={ receivedContent === 0 }
                            />
                        ) : typeof receivedContent === 'string' ||
                        typeof receivedContent === 'number' ? (
                            <ListItemText
                                classes={ { root: classes.item, primary: classes.text } }
                                primary={ discardDate( receivedContent ) }
                                primaryTypographyProps={ { noWrap: true } }
                            />
                        ) : receivedContent === null ? (
                            <ListItemText
                                classes={ { primary: classes.text } }
                                primary={ nullText || '-' }
                            />
                        ) : typeof receivedContent === 'boolean' ? (
                            <ListItemText
                                classes={ { root: classes.item, primary: classes.text } }
                                primary={
                                  <div
                                      className={
                                        receivedContent
                                            ? classes.onStatus
                                            : classes.offStatus
                                      }
                                  />
                                }
                                primaryTypographyProps={ { noWrap: true } }
                            />
                        ) : (
                            <span>{ col }</span>
                        ) }
                      </Grid>
                  )
                } ) }
                { rowActions && (
                    <Grid item xs className={ classes.item }>
                      { rowActions.map( ( { title, action, disabled }, i ) => (
                          <div key={ i } className={classes.buttonContainer}>
                            <ButtonComponent
                                { ...{ disabled } }
                                discrete
                                isLoading={ loading && activeActionIndex === i }
                                small
                                handleClick={ async () => {
                                  setLoading( true )
                                  setActiveActionIndex( i )
                                  await handleRowActionClick( disabled, action )
                                  setLoading( false )
                                  setActiveActionIndex( null )
                                } }
                                aria={ title }>
                              { title }
                            </ButtonComponent>
                          </div>
                      ) ) }
                    </Grid>
                ) }
              </Grid>
            </ListItem>
        ) }
      </Fragment>
  )
}

export default Row
