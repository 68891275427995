import {
  User,
  UsersByEmailByUsernameQuery,
  UsersByEmailByUsernameQueryVariables
} from '../API'
import { callGraphQL } from './apiUtils'


const usersByEmailByUsername = /* GraphQL */ `
    query UsersByEmailByUsername(
        $email: String
        $username: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        usersByEmailByUsername(
            email: $email
            username: $username
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                username
                email
                nombre
                tipo
                idHub
                numerosEmergencia
                telefonosEmergencia
                idPerfil
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`

let user: User | null = null

const AuthStorage = {
  user: {
    get: () => user,
    set: async ( email: string, username: string ) => {
      // get user from api
      try {
        const usersData = await callGraphQL<UsersByEmailByUsernameQuery, UsersByEmailByUsernameQueryVariables>( usersByEmailByUsername, {
          variables: {
            email,
            username: { eq: username }
          }
        } )
        const matchUsersResult = usersData?.data?.usersByEmailByUsername?.items
        if ( matchUsersResult ) {
          user = matchUsersResult[0] as User
        } else {
          user = null
        }

      } catch ( err ) {
        console.log( 'USER ERROR: ', err )
        user = null
      }
    },
    reset: () => {
      user = null
    }
  }
}

export default AuthStorage