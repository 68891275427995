import * as React from 'react'
import { FC } from 'react'

import useSchemas from './form.schema'
import useForm, { FormSchemaTextObject } from '../../../../hooks/useForm'
import { Boton, FormTextInput } from '../../../common'
import { Grid } from '@material-ui/core'

import useStyles from './styles'
import SectionInformation from '../../../common/SectionInformation/SectionInformation'
import { Box } from '@material-ui/core/'

type InputName = 'id'

interface IInput {
  name: InputName,
  label: string
}

export interface CreateHubFormSchemaData {
  id: FormSchemaTextObject
  telefono: FormSchemaTextObject
  direccion: FormSchemaTextObject
  estado: FormSchemaTextObject
  municipio: FormSchemaTextObject
  cp: FormSchemaTextObject
}

interface CreateHubFormProps {
  handleOnCancel: () => void
  onFormSubmit:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
}

const CreateHubForm: FC<CreateHubFormProps> = ( { handleOnCancel, onFormSubmit } ) => {
  const { initialState, validationStateSchema } = useSchemas()
  const styles = useStyles()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useForm<CreateHubFormSchemaData>(
      initialState,
      validationStateSchema,
      async cleanData => await onFormSubmit( cleanData )
  )

  const generalInfoInputs: IInput[] = [
    {
      name: 'id',
      label: 'Smart Hub ID *'
    }
  ]

  return (
      <Grid container className={ styles.formContainer }>
        <SectionInformation title={ 'Información general del Smart Hub' } description={ 'El Smart Hub ID deberá de' +
        ' coincidir con el ID de creación del dispositivo en la nube.' }/>
        <Grid item container direction={ 'column' }>
          {
            generalInfoInputs.map( ( input => (
                <Grid key={ input.name } item className={ styles.inputItem }>
                  <FormTextInput
                      required
                      name={ input.name }
                      label={ input.label }
                      value={ updatedData[input.name].value }
                      errorValue={ updatedData[input.name].error }
                      onChange={ handleUpdateData }
                  />
                </Grid>
            ) ) )
          }
        </Grid>

        <Grid item xs container className={ styles.submit }>

          <Box mr={ 2 }>
            <Boton discrete small aria={ 'cancelar' } handleClick={ handleOnCancel }>
              Cancelar
            </Boton>
          </Box>

          <Boton
              small
              aria={ 'crear smart hub' }
              handleClick={ handleOnSubmit }
              isLoading={ isButtonLoading }
              disabled={ !buttonState }>
            Crear Smart Hub
          </Boton>
        </Grid>
      </Grid>

  )
}

export default CreateHubForm
