import PubSub from '@aws-amplify/pubsub'

const IoT = {
  publish: async ( topic: string, payload: any ) => {
    try {
      await PubSub.publish(
          topic,
          payload
      )
    } catch ( e ) {
      // noinspection JSUnresolvedVariable
      console.log( 'publish error: ', e )
    }
  }
}

export default IoT