import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  gruposRoot: {
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing( 4 ),

    [theme.breakpoints.up( 'md' )]: {}
  },

  textRoot: {
    margin: theme.spacing( 2, 1 ),
    '& h6': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold
    },

    [theme.breakpoints.up( 'md' )]: {
      '& h6': {
        fontSize: theme.typography.h6.fontSize
      }
    }
  },

  subtitle: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    margin: theme.spacing( 4, 0, 2, 0 ),

    [theme.breakpoints.up( 'md' )]: {
      flexDirection: 'row'
    }
  },

  controlesRoot: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  controlsContainer: {},

  title: {
    '& h3': {
      margin: theme.spacing( 2, 0 )
    }
  },

  rootGestion: {
    padding: theme.spacing( 4 )
  },

  rootListaGrupos: {
    padding: theme.spacing( 4 )
  },

  rootCrearHub: {
    padding: theme.spacing( 4 ),
    flexDirection: 'column'
  },

  userCreationContainer: {
    marginBottom: theme.spacing( 1 )
  },

  userDataContainer: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0
  },

  vistaTodosRoot: {},

  dataTableContainer: {
    paddingBottom: theme.spacing( 1 ),
    [theme.breakpoints.up( 'md' )]: {
      paddingBottom: theme.spacing( 0 )
    }
  }
} ) )