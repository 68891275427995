/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHub = /* GraphQL */ `
  mutation CreateHub(
    $input: CreateHubInput!
    $condition: ModelHubConditionInput
  ) {
    createHub(input: $input, condition: $condition) {
      id
      isArmado
      createdAt
      updatedAt
      usuarios {
        items {
          id
          username
          email
          nombre
          tipo
          idHub
          numerosEmergencia
          telefonosEmergencia
          createdAt
          updatedAt
        }
        nextToken
      }
      conexiones {
        items {
          id
          idHub
          descripcion
          tipo
          codigoEmisor
          codigoReceptor
          protocoloReceptor
          pulselengthReceptor
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateHub = /* GraphQL */ `
  mutation UpdateHub(
    $input: UpdateHubInput!
    $condition: ModelHubConditionInput
  ) {
    updateHub(input: $input, condition: $condition) {
      id
      isArmado
      createdAt
      updatedAt
      usuarios {
        items {
          id
          username
          email
          nombre
          tipo
          idHub
          numerosEmergencia
          telefonosEmergencia
          createdAt
          updatedAt
        }
        nextToken
      }
      conexiones {
        items {
          id
          idHub
          descripcion
          tipo
          codigoEmisor
          codigoReceptor
          protocoloReceptor
          pulselengthReceptor
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteHub = /* GraphQL */ `
  mutation DeleteHub(
    $input: DeleteHubInput!
    $condition: ModelHubConditionInput
  ) {
    deleteHub(input: $input, condition: $condition) {
      id
      isArmado
      createdAt
      updatedAt
      usuarios {
        items {
          id
          username
          email
          nombre
          tipo
          idHub
          numerosEmergencia
          telefonosEmergencia
          createdAt
          updatedAt
        }
        nextToken
      }
      conexiones {
        items {
          id
          idHub
          descripcion
          tipo
          codigoEmisor
          codigoReceptor
          protocoloReceptor
          pulselengthReceptor
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      nombre
      tipo
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      numerosEmergencia
      telefonosEmergencia
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      nombre
      tipo
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      numerosEmergencia
      telefonosEmergencia
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      nombre
      tipo
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      numerosEmergencia
      telefonosEmergencia
      createdAt
      updatedAt
    }
  }
`;
export const createConexion = /* GraphQL */ `
  mutation CreateConexion(
    $input: CreateConexionInput!
    $condition: ModelConexionConditionInput
  ) {
    createConexion(input: $input, condition: $condition) {
      id
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      descripcion
      tipo
      codigoEmisor
      codigoReceptor
      protocoloReceptor
      pulselengthReceptor
      createdAt
      updatedAt
    }
  }
`;
export const updateConexion = /* GraphQL */ `
  mutation UpdateConexion(
    $input: UpdateConexionInput!
    $condition: ModelConexionConditionInput
  ) {
    updateConexion(input: $input, condition: $condition) {
      id
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      descripcion
      tipo
      codigoEmisor
      codigoReceptor
      protocoloReceptor
      pulselengthReceptor
      createdAt
      updatedAt
    }
  }
`;
export const deleteConexion = /* GraphQL */ `
  mutation DeleteConexion(
    $input: DeleteConexionInput!
    $condition: ModelConexionConditionInput
  ) {
    deleteConexion(input: $input, condition: $condition) {
      id
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      descripcion
      tipo
      codigoEmisor
      codigoReceptor
      protocoloReceptor
      pulselengthReceptor
      createdAt
      updatedAt
    }
  }
`;
