import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  usuariosRoot: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: theme.spacing( 4 ),

    [theme.breakpoints.up( 'md' )]: {}
  },

  titleContainer: {
    '& h1': {
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'center',
      width: '100%'
    }
  },

  lockContainer: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing( 2 ),
    "& button": {
      width: 80,
      height: 80,
      color: theme.palette.text.lightText,
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.up( 'md' )]: {
      "& button": {
        width: 200,
        height: 200,
        fontSize: theme.typography.h1.fontSize
      },
    }
  }
} ) )