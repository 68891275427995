// noinspection JSUnresolvedFunction,JSUnresolvedVariable

import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'


const attach = async () => {
  const credentials = await Auth.currentCredentials()

  const iot = new AWS.Iot( {
    region: process.env.REACT_APP_REGION,
    credentials: Auth.essentialCredentials( credentials )
  } )

  const policyName = process.env.REACT_APP_IOT_POLICY_NAME
  const target = credentials.identityId

  const { policies } = await iot.listAttachedPolicies( { target } ).promise()

  if ( !policies!.find( ( policy: any ) => policy.policyName === policyName ) ) {
    await iot.attachPolicy( { policyName: policyName!, target } ).promise()
  }
}

const PubSubAttachPolicy = { attach }

export default PubSubAttachPolicy