import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  formItemLayoutRoot: {
    borderLeft: theme.spacing( 3 ),
    borderColor: theme.palette.text.light,
    margin: theme.spacing( 1, 0 ),
    display: 'flex',
    alignItems: ( { textArea } ) => (textArea ? 'flex-start' : 'center'),
    justifyContent: 'flex-end',
    [theme.breakpoints.up( 'md' )]: {
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  labelWrapper: {
    textAlign: 'right'
  },
  textFieldWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  helperClass: {
    width: '100%',
    margin: theme.spacing( 0.5, 1, 0, 0 )
  }
}) )
