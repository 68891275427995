// noinspection JSIgnoredPromiseFromCall

import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

// import useStyles from './styles'
import { Boton, Confirmation, SideMenu } from '../../common'
import {
  Conexion,
  Hub
} from '../../../API'


import { Context as ModalContext, IModalContext } from '../../../context/ModalContext'
import { AiOutlineLock, AiOutlineUnlock, BiArrowBack, BiLogOut } from 'react-icons/all'
import { StateUpdater } from '../VistaHandler'
import { IHubsNav } from './VistaHubs'
import InformacionGeneral from '../CommonViews/InformacionGeneral/InformacionGeneral'
import Conexiones from './Conexiones'
import ConexionesStorage from '../../../lib/ConexionesStorage'
import CreateConexionForm from './CreateConexionForm/CreateConexionForm'
import IoT from '../../../lib/IoT'
import Logs, { ILog } from '../CommonViews/Logs/Logs'
import PubSub from '@aws-amplify/pubsub'
import { Box, Grid } from '@material-ui/core/'
import ButtonComponent from '../../common/Boton/Boton'
import theme from '../../../App.theme'
import useInterval from '../../../hooks/useInterval'
import Gestion from './Gestion'
import { CognitoGroupType, CreadorUsuario, deleteUser } from '../../../lib/UserFactory'
import ModalMessage from '../../common/ModalMessage/ModalMessage'

const usuarioFactory = new CreadorUsuario()

interface IHubDetailView {
  hubsNav: IHubsNav
  handleDetail: ( hubID: string | null ) => void
  hubDetail: Hub | null
  stateUpdater: StateUpdater
}

const HubDetailView: FC<IHubDetailView> = ( { stateUpdater, hubsNav, handleDetail, hubDetail } ) => {
  // const styles = useStyles()
  const hubID = hubsNav.getCurrentHubID()
  const [ conexiones, setConexiones ] = useState<Conexion[]>( [] )
  const [ logs, setLogs ] = useState<ILog[]>( [] )
  const [ deviceStatus, setDeviceStatus ] = useState( false )
  const [ contador, setContador ] = useState( 0 )
  const [ users, setUsers ] = useState<any[]>( [] )
  const [ isLoading, setIsLoading ] = useState<boolean>( false )

  const { openModal, closeModal } = useContext<IModalContext>( ModalContext )
  const {
    getConexiones,
    fetchConexiones,
    borrarConexion,
    crearConexion,
    detalleConexion
  } = ConexionesStorage.conexiones

  useInterval( () => {
    setContador( prevState => prevState + 1 )
  }, 1000 )

  useEffect( () => {
    if ( contador === 20 ) {
      setDeviceStatus( false )
      setContador( 0 )
    }
  }, [ contador, deviceStatus ] )


  useEffect( () => {
    const logSub = PubSub.subscribe( `smarthub/log/${ hubID }` ).subscribe( {
      next: data => {
        setLogs( prevState => [ data.value, ...prevState ] )
      },
      error: error => console.log( error )
    } )

    const resSub = PubSub.subscribe( `smarthub/ota/response` ).subscribe( {
      next: data => {
        if ( data.value.id_hub === hubID ) {
          setLogs( prevState => [ {
            categoria: 'RECIBIDO',
            tipo: 'ACTUALIZACIÓN',
            code: data.value.status,
            timestamp: new Date().toISOString()
          }, ...prevState ] )
        }

      },
      error: error => console.log( error )
    } )

    const reqSub = PubSub.subscribe( `smarthub/ota/request` ).subscribe( {
      next: data => {
        if ( data.value.id_hub === hubID ) {
          setLogs( prevState => [ {
            categoria: 'ENVIADO',
            tipo: 'SOLICITUD DE ACTUALIZACIÓN',
            code: 'OK',
            timestamp: new Date().toISOString()
          }, ...prevState ] )
        }

      },
      error: error => console.log( error )
    } )

    const helloSub = PubSub.subscribe( `smarthub/hello` ).subscribe( {
      next: data => {
        if ( data.value.id_hub === hubID ) {
          setLogs( prevState => [ {
            categoria: 'ENVIADO',
            tipo: 'HELLO!',
            code: 'OK',
            timestamp: new Date().toISOString()
          }, ...prevState ] )
        }

      },
      error: error => console.log( error )
    } )

    const keepaliveSub = PubSub.subscribe( `smarthub/keepalive/${ hubID }` ).subscribe( {
      next: data => {
        setDeviceStatus( true )
      },
      error: error => console.log( error )
    } )

    return () => {
      logSub.unsubscribe()
      resSub.unsubscribe()
      reqSub.unsubscribe()
      helloSub.unsubscribe()
      keepaliveSub.unsubscribe()
    }
    // eslint-disable-next-line
  }, [] )

  const fetch = useCallback(
      async () => {
        if ( hubID ) {
          await fetchConexiones( hubID )
          const c = getConexiones()
          if ( c ) {
            setConexiones( c )
          }
        }
      }, [ fetchConexiones, getConexiones, hubID ] )

  useEffect( () => {
    handleDetail( hubID )
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const handleGetUsers = async ( type: CognitoGroupType ) => {
    if ( hubID ) {
      if ( type === 'usuarios' ) {
        return await usuarioFactory.getUsers( hubID )
      }
    }
    return []
  }

  const fetchUsers = async ( type: CognitoGroupType ) => {
    setIsLoading( true )
    const users = await handleGetUsers( type )
    setUsers( users )
    setIsLoading( false )
  }

  const handleOnCreateUser = async ( {
                                       email,
                                       nombre,
                                       telefono
                                     }: { email: string, nombre: string, telefono: string }, type: CognitoGroupType ) => {
    if ( hubID ) {
      if ( type === 'usuarios' ) {
        await usuarioFactory.createUser( email, nombre, telefono, hubID )
      }
      await fetchUsers( type )
    }
  }

  const handleOnDeleteUser = async ( username: string, type: CognitoGroupType ) => {
    openModal(
        'Eliminar usuario',
        <Confirmation
            text="¿Realmente deseas eliminar a este usuario?"
            onConfirm={ async () => {
              await deleteUser( username )
              await fetchUsers( type )
              await closeModal()
            } }
            onReject={ closeModal }
            confirmText="Eliminar usuario"
        />
    )

  }


  const handleDeleteConexion = async ( idConexion: string ) => {
    const submit = async function() {
      if ( hubID ) {
        await borrarConexion( idConexion )
        await fetch()
        await closeModal()
        await IoT.publish( `smarthub/ota/request`, { id_hub: hubID } )
      }
    }

    openModal(
        'Destruir conexión',
        <Confirmation
            text="Destruir una conexión puede afectar el funcionamiento del Smart Hub. ¿Realmente deseas eliminarla?"
            onConfirm={ submit }
            onReject={ closeModal }
            confirmText="Destruir"
        />
    )

  }

  const handleCreateConexion = async function( formData: any, idHub: string ) {
    const submit = async function( formData: any ) {
      await crearConexion( formData, idHub )
      await fetch()
      await closeModal()
      await IoT.publish( `smarthub/ota/request`, { id_hub: idHub } )
    }

    openModal(
        'Crear conexión',
        <CreateConexionForm handleOnCancel={ closeModal }
                            onFormSubmit={ submit }/>
    )
  }

  const handleDetailConexion = async ( id: string ) => {
    const conexionDetail = await detalleConexion( id )

    if ( conexionDetail ) {

      const informacionGeneralConexion = [
        {
          label: 'ID Conexión:',
          value: conexionDetail.id
        },
        {
          label: 'Descripción:',
          value: conexionDetail.descripcion
        },
        {
          label: 'Tipo:',
          value: conexionDetail.tipo
        },
        {
          label: 'Código emisor:',
          value: conexionDetail.codigoEmisor
        },
        {
          label: 'Código receptor:',
          value: conexionDetail.codigoReceptor
        },
        {
          label: 'Protocolo receptor:',
          value: conexionDetail.protocoloReceptor
        },
        {
          label: 'Pulselength receptor:',
          value: conexionDetail.pulselengthReceptor
        },
        {
          label: 'Tópico MQTT:',
          value: conexionDetail.mqttTopic
        },
        {
          label: 'Payload MQTT:',
          value: conexionDetail.mqttPayload
        }
      ]

      const emitirComando = async () => {
        await IoT.publish( `smarthub/command/${ hubID }`, {
          tipo: conexionDetail.tipo,
          codigo: conexionDetail.codigoReceptor,
          protocolo: conexionDetail.protocoloReceptor,
          pulselength: conexionDetail.pulselengthReceptor,
          isHubArmado: conexionDetail.hub?.isArmado
        } )
      }

      const simularEmisorRF = async () => {
        await IoT.publish( `smarthub/simulate-rf/${ hubID }`, {
          codigo: conexionDetail.codigoEmisor
        } )
      }


      openModal(
          'Detalle de la conexión',
          <InformacionGeneral title={ `` }
                              subtitle={ 'Información general de la' +
                              ' conexión. Si deseas cambiar un parámetro, elimina esta conexión y crea una nueva.' }
                              data={ informacionGeneralConexion }
                              customComponents={ [
                                <Box my={ 1 } key={ `borrar-${ id }` }>
                                  <ButtonComponent
                                      small
                                      disabled={ conexionDetail.tipo === 'ESPECIAL' }
                                      handleClick={ async () => await handleDeleteConexion( id ) }
                                      aria={ 'borrar conexión' }>
                                    Borrar conexión
                                  </ButtonComponent>
                                </Box>,
                                <Box my={ 1 } key={ `activar-${ id }` }>
                                  <ButtonComponent
                                      small
                                      disabled={ conexionDetail.tipo === 'ESPECIAL' || conexionDetail.tipo === 'MQTT' }
                                      handleClick={ emitirComando }
                                      aria={ 'activar receptor' }>
                                    Activar receptor
                                  </ButtonComponent>
                                </Box>,
                                <Box my={ 1 } key={ `simulate-rf-${ id }` }>
                                  <ButtonComponent
                                      small
                                      handleClick={ simularEmisorRF }
                                      aria={ 'simular emisor RF' }>
                                    Simular emisor RF
                                  </ButtonComponent>
                                </Box>

                              ] }
          />
      )
    }

  }

  const secureHub = async ( isArmado: boolean ) => await IoT.publish( `smarthub/secure`, {
    id_hub: hubID,
    secureHub: isArmado
  } )

  const secureHubWithConfirmation = async () => {
    await secureHub( true )
    openModal(
        'Información',
        <ModalMessage text={ 'Seguridad en el Smarthub activada.' }/>
    )
  }

  const unlockHubWithConfirmation = async () => {
    await secureHub( false )
    openModal(
        'Información',
        <ModalMessage text={ 'Seguridad en el Smarthub desactivada.' }/>
    )
  }

  const ota = async () => {
    await IoT.publish( `smarthub/ota/request`, { id_hub: hubDetail?.id } )
    openModal(
        'Información',
        <ModalMessage text={ 'OTA Solicitada. Revisa los logs para más información.' }/>
    )
  }

  const informacionGeneralHub = [
    {
      label: 'Seguridad ',
      value: hubDetail?.isArmado ? 'ACTIVADA' : 'DESACTIVADA'
    },
    {
      label: 'Conectividad con el Smart Hub ',
      value: deviceStatus ?
          <strong style={ { color: theme.palette.status.success } }>CONECTADO</strong> : 'SOLICITANDO...'
    }
  ]

  const menuConfig = [
    {
      title: 'General',
      items: [
        {
          title: 'Información',
          component: hubDetail &&
              <InformacionGeneral title={ `Smart Hub ID: ${ hubDetail?.id }` }
                                  subtitle={ 'Información general del Smart Hub' }
                                  data={ informacionGeneralHub }
                                  customComponents={ [ <Grid container spacing={ 2 }>
                                    <Grid item>
                                      <Boton small
                                             handleClick={ ota }
                                             aria={ 'Actualizar' }>
                                        <Grid container justifyContent={ 'center' }
                                              alignItems={ 'center' }><AiOutlineLock/> <Box ml={ 1 }>OTA</Box></Grid>
                                      </Boton>
                                    </Grid>

                                    <Grid item>
                                      <Boton small handleClick={ secureHubWithConfirmation } aria={ 'Activar' }>
                                        <Grid container justifyContent={ 'center' }
                                              alignItems={ 'center' }><AiOutlineLock/> <Box ml={ 1 }>Activar
                                          seguridad</Box></Grid>
                                      </Boton>
                                    </Grid>

                                    <Grid item>
                                      <Boton small handleClick={ unlockHubWithConfirmation } aria={ 'Desactivar' }>
                                        <Grid container justifyContent={ 'center' }
                                              alignItems={ 'center' }><AiOutlineUnlock/><Box ml={ 1 }>Desactivar
                                          seguridad</Box></Grid>
                                      </Boton>
                                    </Grid>
                                  </Grid> ] }
              />
        }
      ]
    },
    {
      title: 'Tiempo real',
      items: [
        {
          title: 'Conexiones',
          component: (
              <Conexiones title={ 'Conexiones del Smart Hub' }
                          refreshData={ fetch }
                          onDetail={ handleDetailConexion }
                          onCreate={ async ( data ) => hubID && await handleCreateConexion( data, hubID ) }
                          { ...{ conexiones } }
              />
          )
        },
        {
          title: 'Logs',
          component: (
              <Logs logs={ logs } title={ 'Registro de comandos' } onCleanData={ () => setLogs( [] ) }/>
          )
        }
      ]
    },
    {
      title: 'Usuarios',
      items: [
        {
          title: 'Usuarios',
          component: (
              <Gestion title={ 'Usuarios' }
                       fetchUsers={ async () => await fetchUsers( 'usuarios' ) }
                       onCreateUser={ async ( { email, nombre, telefono } ) => await handleOnCreateUser( {
                         email,
                         nombre,
                         telefono
                       }, 'usuarios' ) }
                       onDeleteUser={ async ( username ) => await handleOnDeleteUser( username, 'usuarios' ) }
                       { ...{ users, isLoading } }
              />
          )
        }
      ]
    }
  ]

  const controls = [
    {
      tooltip: 'atrás',
      ariaLabel: 'atrás',
      action: () => hubsNav.setComponente( 'main' ),
      icon: <BiArrowBack/>
    },
    {
      tooltip: 'cerrar sesión',
      ariaLabel: 'cerrar sesión',
      action: () => stateUpdater.resetAuth(),
      icon: <BiLogOut/>
    }
  ]


  return <SideMenu menuItems={ menuConfig } { ...{ controls } }/>

}

export default HubDetailView