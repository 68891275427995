import React, { FC } from 'react'

import { Grid } from '@material-ui/core'
import { FormTextInput } from '..'
import { Typography } from '@material-ui/core'
import theme from '../../../App.theme'

import useStyles from './styles'
import useSchemas from './form.schema'
import { useForm } from '../../../hooks'
import ButtonComponent from '../Boton/Boton'
import { FormSchemaTextObject } from '../../../hooks/useForm'

export interface ConfirmationFormSchemaData {
  confirmation: FormSchemaTextObject
}

interface ConfirmationFormProps {
  text: string
  confirmText: string
  onConfirm:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  onReject:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
}


const Confirmation: FC<ConfirmationFormProps> = ( {
                                                    text,
                                                    onConfirm,
                                                    onReject,
                                                    confirmText = 'Borrar'
                                                  } ) => {
  const { initialState, validationStateSchema } = useSchemas()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useForm<ConfirmationFormSchemaData>(
      initialState,
      validationStateSchema,
      async cleanData => await onConfirm( cleanData )
  )

  const {
    textWrapper,
    confirmationText,
    buttonWrapper,
    helperText,
    helperWrapper
  } = useStyles( { buttonState } )

  return (
      <Grid container style={ {
        flexDirection: 'column',
        maxWidth: 700,
        padding: theme.spacing( 0, 2 ),
        marginTop: theme.spacing( 4 ),
        marginLeft: 'auto',
        marginRight: 'auto'
      } }>
        <Grid container className={ textWrapper }>
          <Typography className={ confirmationText }>{ text }</Typography>
        </Grid>
        <Grid container className={ helperWrapper }>
          <Typography className={ helperText }>
            Introduce "de acuerdo" en el campo de texto
          </Typography>
        </Grid>
        <FormTextInput
            name="confirmation"
            label=""
            value={ updatedData.confirmation.value }
            errorValue={ updatedData.confirmation.error }
            onChange={ handleUpdateData }
        />
        <Grid container className={ buttonWrapper } spacing={ 2 }>
          <Grid item>
            <Typography variant={ 'body2' } onClick={ onReject }>
              Cancelar
            </Typography>
          </Grid>
          <Grid item>
            <ButtonComponent small isLoading={ isButtonLoading } handleClick={ handleOnSubmit } aria={ 'confirmar' }>
              { confirmText }
            </ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default Confirmation
