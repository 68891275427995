import * as React from 'react'
import { useEffect, useRef } from 'react'
import { animated, useSpring } from 'react-spring'

import { Button } from '@material-ui/core'

import useStyles from './styles'
import { useSize } from '../../../hooks'
import Loader from '../Loader/Loader'

interface IButtonComponent {
  handleClick: ( () => void ) | ( () => Promise<void> )
  disabled?: boolean
  isLoading?: boolean
  small?: boolean
  fullWidth?: boolean
  light?: boolean
  discrete?: boolean
  aria: string
  color?: string
}

const ButtonComponent: React.FC<IButtonComponent> = props => {
  const {
    children,
    aria,
    handleClick,
    disabled,
    isLoading,
      color,
    ...rest
  } = props
  const classes = useStyles( { disabled: props.disabled, color: props.color, ...rest } )

  const [ showLoader, setShowLoader ] = React.useState( false )

  useEffect( () => {
    if ( isLoading ) {
      setShowLoader( true )
    }

    if ( !isLoading && showLoader ) {
      const timeout = setTimeout( () => {
        setShowLoader( false )
      }, 400 )

      return () => {
        clearTimeout( timeout )
      }
    }
  }, [ isLoading, showLoader ] )

  const ref = useRef( null )
  const { width, height } = useSize( ref )


  const fadeOutProps = useSpring( { opacity: showLoader ? 1 : 0 } )
  const fadeInProps = useSpring( { opacity: showLoader ? 0 : 1 } )

  return (
      <Button
          aria-label={ aria }
          ref={ ref }
          className={ classes.button }
          disabled={ isLoading || disabled }
          style={
            showLoader
                ? {
                  width: `${ width }px`,
                  height: `${ height }px`
                }
                : {}
          }
          onClick={ handleClick }
      >
        { showLoader ? (
            <animated.div style={ fadeOutProps }>
              <Loader secondary size={props.small ? 20 : 30}/>
            </animated.div>
        ) : (
            <animated.div style={ fadeInProps }>{ children }</animated.div>
        ) }
      </Button>
  )
}

export default ButtonComponent
