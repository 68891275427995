import React, { FC, ReactNode } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import SectionInformation from '../../../common/SectionInformation/SectionInformation'
import theme from '../../../../App.theme'

interface IInformacionGeneral {
  data: any[]
  title: string
  subtitle: string
  customComponents?: ReactNode[]
}

const InformacionGeneral: FC<IInformacionGeneral> = ( { title, subtitle, data, customComponents } ) => {
  const styles = useStyles()

  return (
      <Grid container className={ styles.rootInformacionGeneral } direction={ 'column' } wrap={ 'nowrap' }>
        <SectionInformation title={ 'Información general' } description={ subtitle }/>

        <Grid item container direction={ 'column' }>
          <Grid item className={ styles.item }>
            <Typography variant={ 'h6' }>{ title }</Typography>
          </Grid>
          {
            data.map( ( dataItem, i ) => (
                <Grid key={ i } item className={ styles.item }>
                  <Typography variant={ 'body2' }><strong
                      style={ { marginRight: theme.spacing( 2 ) } }>{ dataItem.label }</strong> { dataItem.value }
                  </Typography>
                </Grid>
            ) )
          }

          { customComponents && customComponents.map( ( component ) => component ) }
        </Grid>
      </Grid>
  )
}

export default InformacionGeneral
