import { adminQuery, callGraphQL } from './apiUtils'
import { UsersByHubQuery, UsersByHubQueryVariables} from '../API'
import {  usersByHub } from '../graphql/queries'

export type UserType = 'USUARIO'
export type CognitoGroupType = 'usuarios'

const TIPO_USUARIO: UserType = 'USUARIO'

export const deleteUser = async ( username: string ): Promise<any> => {

  await adminQuery( 'post', '/deleteUser', {
    body: { username }
  } )
}

abstract class Creator {

  public abstract factoryMethod(): Promise<User>;


  public async createUser( email: string, nombre: string, telefono: string, idHub: string ): Promise<any> {
    const user = await this.factoryMethod()
    return await user.createUser( email, nombre, telefono, idHub )
  }

}


class CreadorUsuario extends Creator {
  public async factoryMethod(): Promise<User> {
    return new Usuario()
  }

  public async getUsers( groupID: string ): Promise<any[]> {
    const usersData = await callGraphQL<UsersByHubQuery,
        UsersByHubQueryVariables>( usersByHub, {
      variables: {
        idHub: groupID
      }
    } )

    return usersData.data?.usersByHub?.items as any[]
  }
}

interface User {
  createUser( email: string, nombre: string, telefono: string, idHub: string ): any
}

class Usuario implements User {

  public async createUser( email: string, nombre: string, telefono: string, idHub: string ): Promise<any> {
    await adminQuery( 'post', '/createUser', {
      body: {
        email,
        nombre,
        idHub,
        tipo: TIPO_USUARIO,
        cognitoGroup: 'usuarios',
        profile: {
          telefono
        }
      }
    } )
  }
}


export { CreadorUsuario }
