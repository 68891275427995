import React, { FC, useState } from 'react'
import { Grid } from '@material-ui/core'

import useStyles from './styles'
import { IVistaNav, StateUpdater } from '../VistaHandler'
import { Hub } from '../../../API'
import ItemListSubview from '../CommonViews/ItemListSubview/ItemListSubview'
import MainView from '../CommonViews/MainView/MainView'
import CrearHub from './CrearHub'
import HubDetailView from './HubDetailView'
import HubStorage from '../../../lib/HubStorage'

export interface IHubsNav extends IVistaNav<HubComponenteType, IPayload, HubSubviewType> {
  getCurrentHubID: () => string | null
}

interface IPayload {
  hubID: string
}

type HubComponenteType = 'main' | 'detail'
type HubSubviewType = 'lista' | 'crear'

interface IVistaHubs {
  stateUpdater: StateUpdater
}

const VistaHubs: FC<IVistaHubs> = ( { stateUpdater } ) => {
  const [ componente, setComponente ] = useState<HubComponenteType>( 'main' )
  const [ subview, setSubview ] = useState<HubSubviewType>( 'lista' )
  const [ hubDetail, setHubDetail ] = useState<Hub | null>( null )
  const [ currentHubID, setCurrentHubID ] = useState<string | null>( null )
  const styles = useStyles()

  const { getHubs, fetchHubs, crearHub, detalleHub } = HubStorage


  const hubsNav: IHubsNav = {
    setComponente: ( c, payload?: IPayload ) => {
      setComponente( c )
      if ( payload ) {
        setCurrentHubID( payload.hubID )
      }
    },
    setSubview: ( subview ) => setSubview( subview ),
    getCurrentSubview: () => subview,
    getCurrentHubID: () => currentHubID
  }

  const handleCreateHub = async ( formData: any ) => {
    await crearHub( formData )
    hubsNav.setSubview( 'lista' )
  }

  const handleOnCancel = () => hubsNav.setSubview( 'lista' )

  const handleHubDetail = async ( idHub: string | null ) => {
    const hd = await detalleHub( idHub )
    if ( hd ) {
      setHubDetail( hd )
    }
  }

  const getHubsMainViewSubview = () => {
    switch ( subview ) {
      case 'crear':
        return (
            <CrearHub { ...{ handleCreateHub, handleOnCancel } }/>
        )
      case 'lista':
        return (
            <ItemListSubview
                componentTitle={ 'Hubs' }
                fetchItems={ fetchHubs }
                getItems={ getHubs }
                onDetail={ ( hubID: number ) => hubsNav.setComponente( 'detail', { hubID: hubID.toString() } ) }
                onCreate={ () => hubsNav.setSubview( 'crear' ) }
                dataTableConfig={ [
                  { id: 0, field: 'Smart Hub ID', colProp: 'id' },
                  { id: 1, field: 'Armado', colProp: 'isArmado' }
                ] }
            />
        )

    }
  }


  const getView = () => {
    switch ( componente ) {
      case 'detail':
        return <HubDetailView handleDetail={ handleHubDetail }            { ...{ hubsNav, hubDetail, stateUpdater } }/>
      case 'main':
        return <MainView subview={ getHubsMainViewSubview() } { ...{ hubsNav, stateUpdater } }/>
    }
  }


  return (
      <Grid container className={ styles.gruposRoot }>
        { getView() }
      </Grid>
  )

}

export default VistaHubs
