const separadorMiles = ( x: string | number ) => {
  if ( typeof x === 'number' ) {
    // const nc = Math.ceil(x)
    const nc = x
    return Number( Math.ceil( Number( parseFloat( String( nc ) ).toFixed( 2 ) ) ) ).toLocaleString( 'en', {
      minimumFractionDigits: Number.isInteger( nc ) ? 0 : 0
    } )
  }
  return x
}

export default separadorMiles
