import React from 'react'
import { useStyles, CssTextField } from './styles'
import FormItemLayout from '../../FormItemLayout/FormItemLayout'


const SearchBox = ( { placeholder, handleChange, disabled, outlined } ) => {
  const styles = useStyles()
  return (
      <>
        { outlined ? (
            <FormItemLayout>
              <CssTextField
                  disabled={ disabled }
                  readOnly={ false }
                  autoFocus={ true }
                  placeholder={ placeholder }
                  type="search"
                  onChange={ handleChange }
                  style={ { width: '100%' } }
              />
            </FormItemLayout>
        ) : (
              <div className={ styles.root }>
                <CssTextField
                    disabled={ disabled }
                    readOnly={ false }
                    autoFocus={ true }
                    placeholder={ placeholder }
                    type="search"
                    onChange={ handleChange }
                    style={ { width: '100%' } }
                />
              </div>
          ) }
      </>
  )
}

export default SearchBox
