import { TextField, makeStyles, withStyles } from '@material-ui/core'


const useStyles = makeStyles( ( theme ) => ({
  root: {
    marginTop: theme.spacing( 1 ),
    marginBottom: theme.spacing( 1 ),
    width: '100%',

  }
}) )

const CssTextField = withStyles( ( theme ) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      fontSize: 16,
      minHeight: theme.spacing( 3 ),
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing( 0.5, 1 ),
      minHeight: ( { multiline } ) => (multiline ? 48 : theme.spacing( 3 )),
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.text.primary,
      '&.focused': {
        backgroundColor: 'red'
      }
    }
  }
}) )( TextField )

export { useStyles, CssTextField }
