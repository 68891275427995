import * as React from 'react'

import CssTextField, { useStyles } from './styles'
import { Typography } from '@material-ui/core'
import Layout from './Layout'

interface IFormTextInput {
  name: string
  label: string
  onChange:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  value: string
  errorValue: string
  disabled?: boolean
  helper?: string
  type?: string
  required?: boolean
}

const FormTextInput: React.FC<IFormTextInput> = ( {
                                                    label,
                                                    required,
                                                    onChange,
                                                    value,
                                                    errorValue,
                                                    helper,
                                                    type = 'text',
                                                    name,
                                                    disabled,
                                                    ...textFieldProps
                                                  } ) => {
  const styles = useStyles()
  return (
      <>
        <Layout>
          <CssTextField
              fullWidth
              placeholder={ label }
              multiline={ false }
              rows={ 4 }
              value={ value === null && type === 'text' ? '-' : value || '' }
              variant="outlined"
              onChange={ onChange }
              autoComplete="off"

              { ...{ type, name, disabled } }
              { ...textFieldProps }
          />
        </Layout>
        { <div className={ styles.errorRoot }>
          { errorValue && (
              <Typography variant="body2" className={ styles.error }>
                { errorValue }
              </Typography>
          ) }

          {
            helper && !errorValue && (
                <Typography variant="caption" className={ styles.helperClass }>
                  { helper }
                </Typography>
            )
          }
        </div> }
      </>
  )
}

export default FormTextInput
