import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import useHooksHelpers, { ITableConfig } from '../../../../hooks/useHooskHelpers'
import { useSize } from '../../../../hooks'
import DataTable from '../../../common/DataTable/DataTable'
import { Boton } from '../../../common'
import { HiRefresh } from 'react-icons/hi'
import { Box } from '@material-ui/core/'
import theme from '../../../../App.theme'
import { FcDepartment } from 'react-icons/all'

interface IItemListSubview {
  componentTitle: string
  fetchItems: () => Promise<void>
  getItems: () => any[] | null
  onDetail: ( id: number, index: number ) => void
  onCreate: () => void
  dataTableConfig: ITableConfig[]
}

const ItemListSubview: FC<IItemListSubview> = ( {
                                                  getItems,
                                                  componentTitle,
                                                  fetchItems,
                                                  onDetail,
                                                  onCreate,
                                                  dataTableConfig
                                                } ) => {
  const styles = useStyles()
  const [ items, setItems ] = useState<any[]>( [] )
  const [ isLoading, setIsLoading ] = useState<boolean>( false )

  const fetch = useCallback(
      async () => {
        setIsLoading( true )
        await fetchItems()
        const i = getItems()
        if ( i ) {
          setItems( i )
        }
        setIsLoading( false )
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ fetchItems ] )

  useEffect( () => {
    // noinspection JSIgnoredPromiseFromCall
    fetch()
  }, [ fetch ] )

  const { listTitles, colProps } = useHooksHelpers( { dataTableConfig } )
  const wrapperRef = useRef( null )
  const { height } = useSize( wrapperRef )


  return (
      <Grid container className={ styles.rootItemList } direction={ 'column' }>
        <Grid item container className={ styles.subtitle }>
          <Grid item container className={ styles.controlesRoot }>

            <Grid container item xs className={ styles.textRoot } justifyContent={ 'center' } alignItems={ 'center' }>
              <Grid item style={ { marginRight: theme.spacing( 1 ) } }>
                <FcDepartment size={ 30 }/>
              </Grid>
              <Grid item xs>
                <Typography variant={ 'h6' }>{ componentTitle }</Typography>
              </Grid>
            </Grid>

            <Box mr={ 2 }>
              <Boton small handleClick={ fetch } aria={ 'refresh' }>
                <Grid container justifyContent={ 'center' } alignItems={ 'center' }><HiRefresh/></Grid>
              </Boton>
            </Box>

            <Grid item>
              <Boton small handleClick={ onCreate } aria={ 'Crear' }>
                <Grid container justifyContent={ 'center' } alignItems={ 'center' }>Crear</Grid>
              </Boton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs>
          <div style={ { flexGrow: 1, flexBasis: 0 } } ref={ wrapperRef }>
            <DataTable
                loader={ isLoading }
                items={ items }
                config={ {
                  listTitles,
                  colProps,
                  idProp: 'id',
                  rowHeight: 60
                } }
                height={ height && height - 60 }
                rowActions={ [
                  {
                    title: 'Ver',
                    action: onDetail,
                    disabled: false
                  }
                ] }
            />
          </div>

        </Grid>


      </Grid>
  )
}
export default ItemListSubview
