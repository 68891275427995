import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  inputItem: {
    minWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing( 0, 0.5 )
  },

  submit: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing( 2, 0 ),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  formContainer: {
    flexDirection: 'column',
    maxWidth: 700,
    margin: theme.spacing( 4, 0 ),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
} ) )