import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

interface IStyleProps {
  discrete?: boolean
}

export default makeStyles( ( theme: ITheme ) => ( {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    // marginBottom: theme.spacing(1),
    borderBottom: ( { discrete }: IStyleProps ) =>
        !discrete ? `1px solid ${ theme.palette.text.disabled }` : `1px solid ${ theme.palette.extras.none }`
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.up( 'md' )]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: theme.spacing( 0.5 )
    }
  },
  title: {
    paddingLeft: 0,
    color: theme.palette.text.disabled
  },
  withPointer: {
    cursor: 'pointer'
  },
  text: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary
  },
  titleRight: {
    paddingLeft: theme.spacing( 2 ),
    color: theme.palette.primary.main
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing( 2 ),
    borderRadius: 4
  },
  emptyListeMessage: {
    margin: theme.spacing( 4, 1 ),
    color: theme.palette.text.disabled
  },
  dataTableWrapper: {
    height: '100%'
  },
  onStatus: {
    margin: theme.spacing( 0, 1 ),
    width: 12,
    height: 12,
    borderRadius: 100,
    background: theme.palette.status.success
  },
  offStatus: {
    margin: theme.spacing( 0, 1 ),
    width: 12,
    height: 12,
    borderRadius: 100,
    background: theme.palette.status.danger
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: theme.typography.body2.fontSize,
    cursor: 'pointer',
    marginRight: theme.spacing( 2 )
  },
  itemShrink: {
    flex: '0 1 auto'
  },
  disabledLink: {
    color: theme.palette.text.disabled,
    textDecoration: 'none',
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing( 2 ),
    cursor: 'default'
  },
  loaderContainer: {
    padding: theme.spacing( 4, 0 ),
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40vh'
  },
  listItemRow: {
    backgroundColor: theme.palette.background.paperLight
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      textDecoration: 'underline'
    },

    [theme.breakpoints.up( 'md' )]: {
      marginRight: theme.spacing( 1 )
    }
  }
} ) )
