/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHub = /* GraphQL */ `
  query GetHub($id: ID!) {
    getHub(id: $id) {
      id
      isArmado
      createdAt
      updatedAt
      usuarios {
        items {
          id
          username
          email
          nombre
          tipo
          idHub
          numerosEmergencia
          telefonosEmergencia
          createdAt
          updatedAt
        }
        nextToken
      }
      conexiones {
        items {
          id
          idHub
          descripcion
          tipo
          codigoEmisor
          codigoReceptor
          protocoloReceptor
          pulselengthReceptor
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listHubs = /* GraphQL */ `
  query ListHubs(
    $filter: ModelHubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      nombre
      tipo
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      numerosEmergencia
      telefonosEmergencia
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        nombre
        tipo
        idHub
        hub {
          id
          isArmado
          createdAt
          updatedAt
        }
        numerosEmergencia
        telefonosEmergencia
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByHub = /* GraphQL */ `
  query UsersByHub(
    $idHub: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByHub(
      idHub: $idHub
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        nombre
        tipo
        idHub
        hub {
          id
          isArmado
          createdAt
          updatedAt
        }
        numerosEmergencia
        telefonosEmergencia
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConexion = /* GraphQL */ `
  query GetConexion($id: ID!) {
    getConexion(id: $id) {
      id
      idHub
      hub {
        id
        isArmado
        createdAt
        updatedAt
        usuarios {
          nextToken
        }
        conexiones {
          nextToken
        }
      }
      descripcion
      tipo
      codigoEmisor
      codigoReceptor
      protocoloReceptor
      pulselengthReceptor
      createdAt
      updatedAt
    }
  }
`;
export const listConexions = /* GraphQL */ `
  query ListConexions(
    $filter: ModelConexionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConexions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idHub
        hub {
          id
          isArmado
          createdAt
          updatedAt
        }
        descripcion
        tipo
        codigoEmisor
        codigoReceptor
        protocoloReceptor
        pulselengthReceptor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conexionByHub = /* GraphQL */ `
  query ConexionByHub(
    $idHub: ID
    $sortDirection: ModelSortDirection
    $filter: ModelConexionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conexionByHub(
      idHub: $idHub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idHub
        hub {
          id
          isArmado
          createdAt
          updatedAt
        }
        descripcion
        tipo
        codigoEmisor
        codigoReceptor
        protocoloReceptor
        pulselengthReceptor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
