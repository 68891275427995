import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"


export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {}
  },

  text: { marginTop: theme.spacing(1) },

  lightText: {
    color: theme.palette.text.lightText
  },

  loaderRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
