import { makeStyles, TextField, withStyles } from '@material-ui/core'
import theme, { ITheme } from '../../../App.theme'

interface IStyleProps {
  multiline: boolean
  disabled?: boolean
}

export default withStyles(() => ({
  root: {
    backgroundColor: 'red',
    borderRadius: theme.roundness,

    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(0)
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      fontSize: 16,

      '& fieldset': {
        border: `1px ${ theme.palette.text.disabled } solid`,

        borderRadius: theme.roundness
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.roundness,
      color: ({disabled}: IStyleProps) =>
          disabled ? theme.palette.text.disabled : theme.palette.text.primary,
      '&.focused': {
        backgroundColor: 'red'
      }
    }
  }
}))(TextField)

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    borderLeft: theme.spacing(3),
    borderColor: theme.palette.text.lightText,
    display: 'flex',
    flexDirection: 'column'
  },

  textFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },

  helperClass: {
    width: '100%',
    margin: theme.spacing(0.5, 1, 0, 0),
    color: theme.palette.text.disabled
  },

  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(0.5)
  },

  required: {
    color: theme.palette.status.danger,
    marginLeft: theme.spacing(0.5),
    height: '100%'
  },

  errorRoot: {
    height: 20
  },

  error: {
    color: theme.palette.status.danger,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'right'
  }
}))
