import { useEffect, useState } from 'react'

const useSize = (ref: any) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    // @ts-ignore
    if (ref.current && ref.current.getBoundingClientRect().width) {
      // @ts-ignore
      setWidth(ref.current.getBoundingClientRect().width)
    }
    // @ts-ignore
    if (ref.current && ref.current.getBoundingClientRect().height) {
      // @ts-ignore
      setHeight(ref.current.getBoundingClientRect().height)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    height,
    width
  }
}

export default useSize