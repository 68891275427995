import { useState, useEffect } from 'react'

interface IUseSearchHook {
  filteredProp: string
  data: any []
  idProp: string
}


const useSearch = ( { filteredProp = 'root', data, idProp }: IUseSearchHook ) => {
  const [ searchField, setSearchField ] = useState( '' )
  const [ selectedData, setSelectedData ] = useState<any>( null )
  const [ searchResults, setSearchResults ] = useState( [] )

  const handleSearch = ( event: any ) => {
    setSearchField( event.target.value )
  }

  useEffect( () => {
    if ( data ) {
      const newDataArr: any[] = data.map( ( result ) => ({
        prop:
            filteredProp === 'root'
            ? { id: parseInt( result[idProp] ), ...result }
            : { id: parseInt( result[idProp] ), ...result[filteredProp] }
      }) )
      setSelectedData( newDataArr )
    }
  }, [ data, filteredProp, idProp ] )

  useEffect( () => {
    if ( selectedData ) {
      const cleanArr = selectedData.map( ( item:any ) => item.prop )
      const filterResults = cleanArr.filter( ( item:any ) => {
        return Object.keys( item ).some( ( key ) => {
          if ( item[key] ) {
            return (
                item[key]
                .toString()
                .toLowerCase()
                .search( searchField.toLowerCase() ) !== -1
            )
          } else {
            return ''
          }
        } )
      } )
      setSearchResults( filterResults )
    }
  }, [ searchField, selectedData ] )

  return { searchResults, handleSearch }
}

export default useSearch
