import * as React from "react"
import { FC, useContext } from "react"

import { Context as ModalContext, IModalContext } from "../../../context/ModalContext"
import Modal from '../Modal/Modal'

const ModalController: FC = () => {
  const {
    state: { hidden, component, title },
    closeModal
  } = useContext<IModalContext>(ModalContext)

  return (
    <Modal title={ title } open={ !hidden } onClose={ closeModal }>
      { component }
    </Modal>
  )
}

export default ModalController
