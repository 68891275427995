import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../../App.theme'


export default makeStyles((theme: ITheme) => ({
  textWrapper: {
    margin: theme.spacing(1, 0),
    justifyContent: 'flex-start',
  },
  confirmationText: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.h6.fontSize,
    textAlign: 'left',
  },
}));
