import * as React from 'react'
import { FC } from 'react'

import useSchemas from './form.schema'
import useForm, { FormSchemaTextObject } from '../../../../hooks/useForm'
import { Boton, FormTextInput } from '../../../common'
import { Grid } from '@material-ui/core'

import useStyles from './styles'

export interface CreateUserFormSchemaData {
  nombre: FormSchemaTextObject
  email: FormSchemaTextObject
  telefono: FormSchemaTextObject
}

interface CreateUserFormProps {
  onFormSubmit:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
}

const CreateUserForm: FC<CreateUserFormProps> = ( { onFormSubmit } ) => {
  const { initialState, validationStateSchema } = useSchemas()
  const styles = useStyles()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useForm<CreateUserFormSchemaData>(
      initialState,
      validationStateSchema,
      async cleanData => {
        await onFormSubmit( cleanData )
        updatedData.nombre.value = ''
        updatedData.email.value = ''
        updatedData.telefono.value = ''
      }
  )

  return (
      <Grid container>
        <Grid item xs={ 3 } className={ styles.inputItem }>
          <FormTextInput
              required
              name="nombre"
              label="Nombre"
              value={ updatedData.nombre.value }
              errorValue={ updatedData.nombre.error }
              onChange={ handleUpdateData }
          />
        </Grid>
        <Grid item xs={ 3 } className={ styles.inputItem }>
          <FormTextInput
              required
              name="email"
              label="Correo"
              value={ updatedData.email.value }
              errorValue={ updatedData.email.error }
              onChange={ handleUpdateData }
          />
        </Grid>
        <Grid item xs={ 3 } className={ styles.inputItem }>
          <FormTextInput
              required
              name="telefono"
              label="Teléfono"
              value={ updatedData.telefono.value }
              errorValue={ updatedData.telefono.error }
              onChange={ handleUpdateData }
          />
        </Grid>
        <Grid item className={ styles.submit }>
          <Boton
              small
              aria={ 'crear usuario' }
              handleClick={ handleOnSubmit }
              isLoading={ isButtonLoading }
              disabled={ !buttonState }>
            Crear usuario
          </Boton>
        </Grid>
      </Grid>
  )
}

export default CreateUserForm
