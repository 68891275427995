import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'


const FormMenuMask = withStyles( ( theme ) => ({
  root: {
    margin:theme.spacing(2,0),
    height: 32,
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${ theme.palette.text.disabled }`,
    fontSize: 16,
    padding: theme.spacing( 1 ),
    transition: theme.transitions.create( [ 'border-color', 'box-shadow' ] ),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      boxShadow: theme.shadows[2]
    },
    'label + &': {
      marginTop: theme.spacing( 3 )
    }
  }
}) )( Button )

const useStyles = makeStyles( ( theme ) => ({
  popper: {
    zIndex: theme.zIndex.modal + 1,
    width: `calc(100% - ${ theme.spacing( 4 ) }px)`,
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up( 'md' )]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  labelClass: {
    textAlign: 'left',
    textTransform: 'none'
  },
  arrow: {
    color: theme.palette.text.disabled
  },
  paper: {
    height: '100%',
    maxHeight: 400,
    overflow: 'scroll',
    borderRadius: theme.roundnessSmall,
    padding: theme.spacing( 1 ),
    boxShadow: theme.boxShadowShort,
    backgroundColor: theme.palette.background.paper,
    border: `1px ${ theme.palette.text.disabled } solid`
  }
}) )

export { FormMenuMask, useStyles }
