import { FormSchemaTextObject, FormSchemaValidation } from '../../../../hooks/useForm'

interface IFormType<T> {
  descripcion: T
  codigoEmisor: T
  codigoReceptor: T
  protocoloReceptor: T
  pulselengthReceptor: T
  mqttTopic: T
  mqttPayload: T
}

interface Schema {
  initialState: IFormType<FormSchemaTextObject>
  validationStateSchema: IFormType<FormSchemaValidation>
}

const CreateUserSchema = (): Schema => ( {
  initialState: {
    descripcion: { value: '', error: '' },
    codigoEmisor: { value: '', error: '' },
    codigoReceptor: { value: '', error: '' },
    protocoloReceptor: { value: '', error: '' },
    pulselengthReceptor: { value: '', error: '' },
    mqttTopic: { value: '', error: '' },
    mqttPayload: { value: '', error: '' }
  },
  validationStateSchema: {
    descripcion: { required: true },
    codigoEmisor: { required: true },
    codigoReceptor: { required: false },
    protocoloReceptor: { required: false },
    pulselengthReceptor: { required: false },
    mqttTopic: { required: false },
    mqttPayload: { required: false }
  }
} )


export default CreateUserSchema