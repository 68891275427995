import { makeStyles } from '@material-ui/core'
import { ITheme } from '../../../App.theme'

export default makeStyles( ( theme: ITheme ) => ( {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxHeight: '90vh',
    margin: 'auto auto',
    outline: ' none',
    maxWidth: '100%',
    [theme.breakpoints.up( 'md' )]: {}
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing( 0.5 ),
    minHeight: 250,
    display: 'flex',
    maxWidth: '100%'
  },
  card: {
    borderRadius: theme.spacing( 0.5 ),
    padding: theme.spacing( 2 ),
    minHeight: '100%',
    minWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'scroll'
  },
  action: {
    margin: theme.spacing( 0 )
  },
  header: {
    padding: theme.spacing( 0 )
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing( 0 ),

    }
  },
  titleClass: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  },
  closeButton: {
    color: theme.palette.text.primary
  }
} ) )
