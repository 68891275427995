import { createTheme, Theme, ThemeOptions } from '@material-ui/core/styles'

import { Palette } from '@material-ui/core/styles/createPalette'

interface ITransition {
  default: string
  slowEaseInOut: string
  fastEaseInOut: string
}

interface IPalette extends Palette {
  text: {
    // TEXT
    primary: string
    secondary: string
    disabled: string
    hint: string
    lightText: string
    lightMild: string
    mildText: string
    superLight: string
  }

  background: {
    // BACKGROUND
    default: string
    paper: string
    paperLight: string
    darkBackground: string
    lightBackground: string
  }

  overlays: {
    // OVERLAYS
    darkOverlay: string
    overlay: string
    lightOverlay: string
    whiteOverlay: string
    gradientOverlay: string
  }

  status: {
    // STATUS
    danger: string
    success: string
    warning: string
  }

  extras: {
    // EXTRAS
    none: string
    //TRANSITIONS
    transitions: ITransition
  }
}

export interface ITheme extends Theme {
  palette: IPalette
  roundness: number
  hairlineWidth: number
  letterspacing: ( spacing: number ) => string
  lineHeight: ( spacing: number ) => string
}

interface IThemeOptions extends ThemeOptions {
  palette: IPalette
  roundness: number
  hairlineWidth: number
  letterspacing: ( spacing: number ) => string
  lineHeight: ( spacing: number ) => string
}

const theme = createTheme( {
  palette: {
    primary: {
      main: '#6B789B'
    },

    secondary: {
      main: '#F2B138'
    },

    text: {
      // TEXT
      primary: 'rgb(21, 24, 25)',
      secondary: 'rgb(21, 24, 25)',
      hint: '#90B29B',
      disabled: 'rgba(21, 24, 25, 0.75)',

      lightText: 'white',
      lightMild: '#d9d9d9',
      mildText: 'rgba(21, 24, 25, 0.5)',
      superLight: 'rgba(21, 24, 25, 0.3)'
    },

    background: {
      // BACKGROUND
      default: '#f5f5f5',
      paper: 'white',
      paperLight: '#EDEFF5',
      darkBackground: '#353d40',
      lightBackground: 'white'
    },

    overlays: {
      // OVERLAYS
      darkOverlay: 'rgba(0,0,0,0.75)',
      overlay: 'rgba(0,0,0,0.50)',
      lightOverlay: 'rgba(0,0,0,0.20)',
      whiteOverlay: 'rgba(255,255,255,0.35)',
      gradientOverlay:
          'linear-gradient(180deg, rgba(21, 24, 25) 0%, rgba(255,255,255,1) 100%)'
    },

    status: {
      // STATUS
      danger: '#db4848',
      success: '#009966',
      warning: '#ff9800'
    },

    extras: {
      // EXTRAS
      none: 'transparent',
      transitions: {
        default: 'all 0.3s ease-in-out',
        slowEaseInOut: 'all 1.2s ease-in-out',
        fastEaseInOut: 'all 0.3s ease-in-out'
      }
    }
  },
  // TYPOGRAPHY
  typography: {
    fontFamily: [ 'Montserrat', 'Source Sans Pro' ].join( ',' )
  },
  // UTILITIES
  roundness: 4,
  hairlineWidth: 1,
  letterspacing: num => `${ num }px`,
  lineHeight: num => `${ num }rem`
} as IThemeOptions ) as ITheme

export default theme
