import * as React from 'react'
import Loader from 'react-loader-spinner'

import { makeStyles } from '@material-ui/core'
import theme from '../../../App.theme'
import { FC } from 'react'

interface ILoaderComponent {
  size: number
  light?: boolean
  secondary?: boolean
}


const useStyles = makeStyles( () => ( {
  root: {
    '& div': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
} ) )

const LoaderComponent: FC<ILoaderComponent> = ( { secondary, light, size } ) => {
  const { root } = useStyles()

  return <div className={ root }>
    {/*
 // @ts-ignore */ }
    <Loader
        type="Circles"
        color={
          secondary
              ? theme.palette.secondary.main
              : light
                  ? theme.palette.text.lightText
                  : theme.palette.primary.main
        }
        height={ size }
        width={ size }
    />
  </div>

}

export default LoaderComponent
