import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import '@aws-amplify/ui/dist/style.css'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'

import 'moment/locale/es'

import Amplify, { I18n } from 'aws-amplify'
import awsExports from './aws-exports'
import internationalization from './content/internationalization'

Amplify.configure( awsExports )

I18n.setLanguage( 'es' )
I18n.putVocabularies( internationalization )

Amplify.addPluggable( new AWSIoTProvider( {
  aws_pubsub_region: process.env.REACT_APP_REGION,
  aws_pubsub_endpoint: process.env.REACT_APP_ENDPOINT
} ) )

ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById( 'root' )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

serviceWorkerRegistration.register();
