import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import { useSearch } from '../../../../hooks'
import Searchbox from './Searchbox/Searchbox'

import useStyles from './styles'


const SearchList = ( { listConfig, handleSelectItem, placeholder, removeSearch } ) => {
  const styles = useStyles()
  const { idProp, data } = listConfig
  const { searchResults, handleSearch } = useSearch( {
    data: data,
    idProp: idProp
  } )

  return (
      <Grid className={ styles.searchboxRoot }>
        { !removeSearch && <Searchbox handleChange={ handleSearch } { ...{ placeholder } } /> }
        <Grid container className={ styles.searchList }>
          {
            searchResults.map( ( result, index ) => {
              return (
                  <Grid key={ Math.floor( Math.random() * 10000 ) + 1 } container item
                        onClick={ () => handleSelectItem( result, index ) }
                        className={ styles.searchResultItem }>
                    <Typography component={ 'p' }>
                      { result[idProp] }
                    </Typography>
                  </Grid>
              )
            } )
          }
        </Grid>
      </Grid>
  )
}

export default SearchList
