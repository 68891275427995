import { useMemo } from 'react'
import { IChip } from '../components/common/DataTable/DataTable'


export interface ITableConfig {
  id: number
  field: string
  colProp: string
  chip?: IChip
}

interface IHelpersHookProps {
  dataTableConfig: ITableConfig[]
}

const useHooksHelpers = ({dataTableConfig}: IHelpersHookProps): { colProps: string[]; listTitles: string[] } => {
  return {

    listTitles: useMemo(() => dataTableConfig.map(e => e.field), [
      dataTableConfig
    ]),

    colProps: useMemo(() => dataTableConfig.map(e => e.colProp), [
      dataTableConfig
    ])
  }
}

export default useHooksHelpers
