import React, { FC, useRef, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import useHooksHelpers, { ITableConfig } from '../../../hooks/useHooskHelpers'
import { useSize } from '../../../hooks'
import DataTable from '../../common/DataTable/DataTable'
import { Boton } from '../../common'
import { HiRefresh } from 'react-icons/hi'
import { Conexion } from '../../../API'

interface IConexiones {
  title: string
  conexiones: Partial<Conexion>[]
  refreshData: () => Promise<void>
  onCreate:
      | ( ( ...args: any[] ) => void )
      | ( ( ...args: any[] ) => Promise<void> )
  onDetail: ( id: string, index: number ) => Promise<void>
}

const Conexiones: FC<IConexiones> = ( {
                                        title,
                                        conexiones,
                                        refreshData,
                                        onDetail,
                                        onCreate
                                      } ) => {
  const styles = useStyles()

  const dataTableConfig: ITableConfig[] = [
    // { id: 0, field: 'ID', colProp: 'id' },
    { id: 1, field: 'Descripción', colProp: 'descripcion' },
    { id: 2, field: 'Tipo', colProp: 'tipo' },
    { id: 3, field: 'Codigo Emisor', colProp: 'codigoEmisor' }
  ]

  const { listTitles, colProps } = useHooksHelpers( {
    dataTableConfig
  } )

  const wrapperRef = useRef( null )
  const { height } = useSize( wrapperRef )

  const [ loading, setLoading ] = useState( false )

  const handleRefresh = async () => {
    setLoading( true )
    await refreshData()
    setLoading( false )
  }


  return (
      <Grid container className={ styles.rootGestion } direction={ 'column' }>
        <Grid item container className={ `${ styles.controlesRoot } ${ styles.controlsContainer }` }>
          <Grid item xs container direction={ 'column' }>
            <Typography variant={ 'h6' }>{ title }</Typography>
          </Grid>
          <Box mr={ 2 }>
            <Boton small handleClick={ handleRefresh } aria={ 'refresh' }>
              <Grid container justifyContent={ 'center' } alignItems={ 'center' }><HiRefresh/></Grid>
            </Boton>
          </Box>

          <Grid item>
            <Boton small handleClick={ onCreate } aria={ 'Crear' }>
              <Grid container justifyContent={ 'center' } alignItems={ 'center' }>Crear conexión</Grid>
            </Boton>
          </Grid>

        </Grid>

        <Grid item container xs>
          <div style={ { flexGrow: 1, flexBasis: 0 } } ref={ wrapperRef }>
            <DataTable
                loader={ loading }
                items={ conexiones }
                config={ {
                  listTitles,
                  colProps,
                  idProp: 'id',
                  rowHeight: 60
                } }
                height={ height && height - 60 }
                rowActions={ [
                  {
                    title: 'Ver',
                    action: ( id, index ) => onDetail( id.toString(), index ),
                    disabled: false
                  }
                ] }
            />
          </div>

        </Grid>


      </Grid>
  )
}
export default Conexiones
