import { FC } from 'react'
import * as React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import Loader from '../Loader/Loader'

interface IActivityLoader {
  text: string
  light?: boolean
}

const ActivityLoader: FC<IActivityLoader> = ( { text, light } ) => {
  const styles = useStyles()

  return (
      <Grid
          container
          item
          xs
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={ { height: '100%' } }
      >
        <Grid item>
          <Loader size={ 80 } { ...{ light } } />
        </Grid>
        <Grid item>
          <Typography
              variant="body2"
              style={ { fontWeight: 'bold' } }
              className={ light ? `${ styles.text } ${ styles.lightText }` : styles.text }
          >
            { text }
          </Typography>
        </Grid>
      </Grid>
  )
}

export default ActivityLoader
